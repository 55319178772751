/**
 * Created by osirvent on 30/06/2016.
 */
angular
    .module('annexaApp')
    .controller('TramController', ['$scope', '$rootScope', 'HeaderService', '$state', 'Language', 'RestService', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'TableFilter', 'BoxFactory', 'DccumentsFactory', '$filter', 'TramFactory', 'GlobalDataFactory', function ($scope, $rootScope, HeaderService, $state, Language, RestService, AnnexaFormlyFactory, AnnexaModalFactory, TableFilter, BoxFactory, DccumentsFactory, $filter, TramFactory, GlobalDataFactory) {
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.tram.start' || message.state.name == 'annexa.tram.pending' || message.state.name == 'annexa.tram.view' || message.state.name == 'annexa.tram.responsable' || message.state.name == 'annexa.tram.dossierRightAccess') {
                
            	$rootScope.headButtons = [];
            	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#dataTableInput', 'fa fa-download', 'global.tram.literals.export_dossier', undefined, 'exportDossier'));
            	
            	$rootScope.subHeadButtons = [];

                $rootScope.subHeadButtons.push(new HeadButtonSearch('#tableFilter'));

                if(message.state.name == 'annexa.tram.pending' && $rootScope.esetMode) {
                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#dataTableInput','fa-plus fa-fw','global.literals.initExp',undefined,'initExp'))
                }


                $rootScope.subHeadTabs = [
                    {
                        state: 'annexa.tram.pending',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.pending' ? 'active' : ''),
                        name: 'global.tram.tabs.pending.title',
                        permissions: ['process_dossier']
                    },
                    {
                        state: 'annexa.tram.responsable',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.responsable' ? 'active' : ''),
                        name: 'global.tram.tabs.responsable.title',
                        permissions: ['process_dossier', 'view_dossier']
                    },
                    {
                        state: 'annexa.tram.view',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.view' ? 'active' : ''),
                        name: 'global.tram.tabs.view.title',
                        permissions: ['process_dossier', 'view_dossier']
                    },
                    {
                        state: 'annexa.tram.dossierRightAccess',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.dossierRightAccess' ? 'active' : ''),
                        name: 'global.tram.tabs.dossierRightAccess.title',
                        permissions: ['process_dossier', 'view_dossier']
                    }
                ];

                if(!$rootScope.esetMode) {
                    $rootScope.subHeadTabs.push({
                        state: 'annexa.tram.start',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.start' ? 'active' : ''),
                        name: 'global.tram.tabs.start.title',
                        permissions: ['start_dossier']
                    });
                }
            }
        });

        HeaderService.changeState($state.current);
        
        $scope.languageColumn = Language.getActiveColumn();
        $scope.informationLanguageColumn = "";
        if($scope.languageColumn) {
            $scope.informationLanguageColumn = "informationLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);
        }

        //region Filtre pestanya documents

        $scope.filterTabDocuments = [
            { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
            { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
            { id: 'type', type: 'select', order: 2, label: 'global.literals.docType', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: $scope.languageColumn },
            { id: 'transactionType', type: 'select', order: 3, label: 'global.tram.literals.transaction_type', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: $scope.languageColumn }
        ]

        //endregion

        //region Taula pestanya documents

        var docStatusTitle = function() {
            var content = '';

            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown" title="{{\'global.literals.showOptions\'}}">';
            content += '            <span class="fa-stack" aria-hidden="true">';
            content += '                <i class="fa fa-{{headerIconDocStatus(true)}} text-{{headerIconDocStatus(false)}} text-lg fa-stack-1x"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="status in statuses | orderBy:\'orderStatus\'" ng-click="filterData.status = (!status.id || status.id == 0 ?  \'\' : status.id); tableDefinition.reloadInternalData(false);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{status.icon}} text-{{status.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="status.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="global.documentState.{{status.name}}" ng-if="status.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }

        var docStatusRender = function(data, type, full, meta) {
            var content = '';

            if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
                var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                if(status) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
                    content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }

        var actionsTitle = function () {
            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
        }

        actionsRender = function (data, type, full, meta) {
            return '<annexa-document-actions-button dossier-transaction-document="tableObjects[' + full.id + ']" list="true"></annexa-document-actions-button>';
        };

        $scope.columnsTabDocuments = [
            { id: 'document.publicDocument', column: new BooleanColumn($filter, 'global.documentState.public', 'public') },
            { id: 'document.docStatus', width: '53px', title: docStatusTitle(), render: docStatusRender, sortable: false },
            { id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.document.id; if(full.document.code) { code = full.document.code.substr((full.code.indexOf(full.document.id) - 2), full.document.id.toString().length + 2); } code;') },
            { id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.name', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var name = full.document.name; name;') },
            { id: 'dossierTransaction.transactionType.' + $scope.languageColumn, width: '140px', column: new DatabaseTranslatedColumn($filter, 'global.tram.literals.transaction_type', $scope.languageColumn) },
            { id: 'dossierTransaction.' + $scope.languageColumn, title: $filter('translate')('global.literals.transaction') },
            { id: 'actions', columnName: 'id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender }
        ]

        //endregion
        
        $scope.exportDossier = function () {
        	TramFactory.exportDossier();
        };
        
    }])
    .controller('TramStartController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'TramFactory', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory','HelperService', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, TramFactory, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory,HelperService) {
        $scope.tfilter = [
            { id: 'family', type: 'select-tree', order: 0, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('tramitation','tableProcedure','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableProcedure','family')},
            { id: 'id', type: 'text', order: 1, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tramitation','tableProcedure','id'), negated:HelperService.getPrefilterNegated('tramitation','tableProcedure','id')},
            { id: 'acronym', type: 'text', order: 2, label: 'global.literals.acronym', preFilter:HelperService.getPrefilter('tramitation','tableProcedure','acronym'), negated:HelperService.getPrefilterNegated('tramitation','tableProcedure','acronym')},
            { id: Language.getActiveColumn(), type: 'text', order: 3, label: 'global.literals.procedure', preFilter:HelperService.getPrefilter('tramitation','tableProcedure','language'), negated:HelperService.getPrefilterNegated('tramitation','tableProcedure','language')}
        ];
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }
        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn };

        var getFilterCall = function() {
            return TableFilterFactory.getFilterCall($scope.tfilter);
        }

        var getFilterCallAux = function () {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.applyPermissions = true;
            return filterCallAux;
        }
        var ProcedureActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.tram.tabs.start.title','annexa.tram.newdossier({ procedure: \'[data]\' })',undefined,true));

        var columnsAux = [
            { id: 'family.' + Language.getActiveColumn(), column: new DatabaseTranslatedColumn($filter, 'global.literals.family', Language.getActiveColumn())},
            { id: 'id',  title: $filter('translate')('global.literals.code')},
            { id: 'acronym', title: $filter('translate')('global.literals.acronym')},
            { id: Language.getActiveColumn(), column: new DatabaseTranslatedColumn($filter, 'global.literals.procedure', Language.getActiveColumn())},
            { id: 'actions', columnName: 'id', width: '150px', className: 'text-center', column: ProcedureActionsColumn,  sortable: false}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_start', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_start', columnsAux);
        $scope.tableOrderProperties = {sortName: 'family', sort: [[0, 'asc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }
        $scope.tableDefinition = {
            id: 'tableProcedure',
            origin: 'tram',
            objectType: 'Procedure',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }


    }])
    .controller('TramPendingController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'HeaderService', '$state', 'RegFactory', 'RegModals', 'CommonService', 'AnnexaFormlyFactory', 'TableFilterFactory', 'RouteStateFactory', 'GlobalDataFactory', 'DialogsFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, HeaderService, $state, RegFactory, RegModals, CommonService, AnnexaFormlyFactory, TableFilterFactory, RouteStateFactory, GlobalDataFactory, DialogsFactory) {
        var expiredStates = [
            { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
            { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
        ];
        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, favorite: '', langColumn: $scope.languageColumn };
        var routeStateColumnsFilter = RouteStateFactory.getRouteState($state.current, 'ColumnsFilter');
        if(routeStateColumnsFilter) {
            $scope.filterData = routeStateColumnsFilter.state;
        }

        var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
        	delete x['userProfiles'];
        	delete x['ficticio'];
        });
        
        $scope.tfilter = [];
        $scope.tfilter.push({ id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','dossierNumber')});
        $scope.tfilter.push({ id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','family')});
        $scope.tfilter.push({ id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','procedure'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','procedure')});
        $scope.tfilter.push({ id: 'procedureClassification', type: 'text', order: 3, label: 'global.literals.classification', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','procedureClassification'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','procedureClassification')});
        $scope.tfilter.push({ id: 'subject', type: 'text', order: 4, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','subject')});
        $scope.tfilter.push({ id: 'thirds', type: 'text', order: 5, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','thirds')});
        $scope.tfilter.push({ id: Language.getActiveColumn(), type: 'text', order: 6, label: 'global.literals.transaction', preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','language'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','language')});
        $scope.tfilter.push({ id: 'custom_fields_search', type: 'text', order: 7, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','custom_fields_search')});
        $scope.tfilter.push({ id: 'dates', type: 'dateRange', order: 8, label: 'global.tram.literals.startDateTram', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','dates'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','dates')});
        $scope.tfilter.push({ id: 'creator', type: 'text', order: 9, label: 'global.tram.literals.tramCreator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','creator')});
        $scope.tfilter.push({ id: 'dossierResponsibleUser', type: 'text', order: 10, label: 'global.tram.literals.dossierResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','dossierResponsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','dossierResponsibleUser')});
        if ($rootScope.esetMode) {
        	$scope.tfilter.push({ id: 'responsibleUser', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true, model:(($rootScope.LoggedUser)?($rootScope.LoggedUser.name+" "+$rootScope.LoggedUser.surename1+(($rootScope.LoggedUser.surename2)?' '+$rootScope.LoggedUser.surename2:'')):undefined), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','responsibleUser')});
        } else {        	
        	$scope.tfilter.push({ id: 'responsibleUser', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','responsibleUser')});
        }
        $scope.tfilter.push({ id: 'responsibleTramitProfile', type: 'select-multiple', order: 11, label: 'global.literals.responsibleTramitProfile', dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','responsibleTramitProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','responsibleTramitProfile')});
        $scope.tfilter.push({ id: 'register_entry', type: 'text', order: 12, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','register_entry')});
        $scope.tfilter.push({ id: 'expired', type: 'select', order: 13, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy(expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','expired',expiredStates,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','expired')});
        $scope.tfilter.push({ id: 'address', type: 'text', order: 14, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','address')});
		
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }

        var prefilter = undefined;
        if($stateParams.type){
            prefilter = {};
            var filterExpired = $linq($scope.tfilter).where("x => x.id == 'expired'").toArray();
            if($stateParams.type == 'expired' && filterExpired){
                filterExpired[0].preFilter = 'expired';
                prefilter.expired = {value:'expired'};
            }else if($stateParams.type == 'favorit'){
                $scope.filterData.favorite = $stateParams.type;
            }
        }

        $scope.FavoritesColumnRenderFunction = function (data) {
            if(data) {
                var users = $linq(data)
                    .where("x => x.user.id == " + $rootScope.LoggedUser.id)
                    .toArray();
                if (users.length != 0) {
                    return 'favorit';
                } else {
                    return 'nofavorit';
                }
            }
            return '';
        };

        $scope.FavoritesColumnModificar = function(id) {
            TramFactory.modifyFavorite(id, $rootScope.LoggedUser.id).then(function(data){
                $scope.tableDefinition.reloadInternalData(false,true);
            }).catch(function(error){
                $scope.tableDefinition.reloadInternalData(false, true);
            });
        }

        $scope.FavoritesColumn = new IconSearchColumn(
            'dossier.favoriteUsers',
            '<iconsearchcolumn definition="FavoritesColumn" filterModel="filterData" fieldName="favorite"/>',
            '5%',
            new IconSearchRender($scope, 'FavoritesColumn'),
            false,
            true,
            [
                new IconSearchOption('favorit','favorit', 'fa-star text-warn text-lg', 'fa-star text-warn text-lg', 'global.literals.favorite'),
                new IconSearchOption('nofavorit','nofavorit', 'fa-star-o text-lg', 'fa-star-o text-lg', 'global.literals.noFavorite'),
            ],
            'tableDefinition',
            $scope.FavoritesColumnRenderFunction,
            'FavoritesColumnModificar'
        );

        var literalDays = !$rootScope.esetMode ? 'global.literals.days' : 'global.literals.dossierDays';

        var DaysColumn = new RemaingDaysColumn($filter, literalDays, HelperService);
        var TransactionDaysColumn = new RemaingDaysColumn($filter, 'global.literals.transactionDays', HelperService);
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn);
        var TransactionColumn = new TransactionNameColumn($filter, 'global.literals.transaction', $scope.languageColumn);
        var SubjectColumn = new DossierSubjectColumn($filter, 'global.literals.subject', $scope.languageColumn);
       	var transactionCreatorColumn = new TransactionCreatorColumn($filter, 'global.tram.literals.tramCreator');
       	var dossierResponsibleColumn = new TransactionDossierResponsibleColumn($filter, 'global.tram.literals.dossierResponsible');
        var transactionResponsibleColumn = new TransactionResponsibleColumn($filter, 'global.tram.literals.tramResponsible');
        var transactionResponsibleProfileColumn = new TransactionResponsibleProfileColumn($filter, 'global.literals.responsibleTramitProfile', $scope.languageColumn);
        var TranCounterColumn = new TransactionsCounterColumn();
        var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
        var RelatedColumn = new RelatedDossiersColumn();
		var addressColumn = new AddressesColumn($filter('translate')('global.territory.list.address'), $scope.languageColumn);
        var DossierTransactionActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','openDossier([data], [data2], [dossier.bigDossier])',undefined,false,undefined, undefined, ['dossier.bigDossier']), undefined, 'id');
        var PrincipalColumn = new PrincipalThirdColumn($filter,'global.literals.principalDest');
        var TransactionType = new DatabaseTranslatedColumn($filter, 'global.tram.literals.transaction_type', $scope.languageColumn);
		var profilesColumn = new ProfilesColumn($filter('translate')('global.literals.responsiblesDossierProfiles'), $scope.languageColumn);

        var DossierNumberColumn = new LinkColumn($filter, 'global.literals.code', 'viewDossier', 'dossier.id' );

        var columnsAux = [];
        if(!$rootScope.esetMode) {
            columnsAux = [
                { id: 'dossier.favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender()},
                { id: 'transactionRemainingDays', width: '40px', column: TransactionDaysColumn },
                { id: 'dossier.dossierNumber', width: '140px', title:$filter('translate')('global.literals.code')},
                { id: 'dossier.procedure.' + ProcedureColumn.getColumn(), width: '140px', title:  ProcedureColumn.getTitle() },
                { id: TransactionColumn.getColumn(), width: '150px', title: TransactionColumn.getTitle(),  render: TransactionColumn.getRender },
                { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
                { id: 'dossier.subject', width: '150px', title: SubjectColumn.getTitle(),  render: SubjectColumn.getRender },
                { id: 'dossier.principalThird', width: '200px', column:  PrincipalColumn, sortable: false },
                { id: 'startDate', width: '50px', column:  transactionCreatorColumn },
                { id: 'dossier.responsibleUser.completeName', width: '50px', column: dossierResponsibleColumn },
                { id: 'dossier.dossierResponsibleProfiles', width: '50px', column: profilesColumn },
                { id: 'responsibleUser.completeName', width: '50px', column:  transactionResponsibleColumn },
                { id: 'processProfiles.profile.' + $scope.languageColumn, width: '50px', column:  transactionResponsibleProfileColumn },
                { id: 'dossier.relatedDossiersNumber', width: '40px', render: RelatedColumn.getRender, sortable: false },
				{ id: 'dossier.addresses', column: addressColumn, sortable: false },
                { id: 'actions', columnName: 'dossier.id', width: '100px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
            ];
        } else {
            columnsAux = [
                { id: 'dossier.favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender()},
                { id: 'transactionRemainingDays', width: '40px', column: TransactionDaysColumn },
                { id: 'dossier.dossierNumber', width: '140px', title:$filter('translate')('global.literals.code')},
                { id: 'transactionType.' + TransactionType.getColumn(), width: '140px', title: TransactionType.getTitle()},
                { id: 'dossier.procedure.' + ProcedureColumn.getColumn(), width: '140px', title:  ProcedureColumn.getTitle() },
                { id: TransactionColumn.getColumn(), width: '150px', title: TransactionColumn.getTitle(),  render: TransactionColumn.getRender },
                { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
                { id: 'dossier.subject', width: '150px', title: SubjectColumn.getTitle(),  render: SubjectColumn.getRender },
                { id: 'dossier.principalThird', width: '200px', column:  PrincipalColumn, sortable: false },
                { id: 'startDate', width: '50px', column:  transactionCreatorColumn },
                { id: 'dossier.responsibleUser.completeName', width: '50px', column: dossierResponsibleColumn },
                { id: 'responsibleUser.completeName', width: '50px', column:  transactionResponsibleColumn },
                { id: 'processProfiles.profile.' + $scope.languageColumn, width: '50px', column:  transactionResponsibleProfileColumn },
                { id: 'dossier.relatedDossiersNumber', width: '40px', render: RelatedColumn.getRender, sortable: false },
                { id: 'dossier.addresses', column: addressColumn, sortable: false },
                { id: 'actions', columnName: 'dossier.id', width: '100px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
            ];
        }

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_pending', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_pending', columnsAux);
        $scope.tableOrderProperties = {sortName: 'startDate', sort: [[1,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            filterCall.actual = true;
            filterCall.dossier = {closed:false};
            return filterCall;
        }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.favorite = $scope.filterData.favorite;
            if(filterCallAux.expired){
                if(filterCallAux.expired.id == 'expired'){
                    filterCallAux.expired = true;
                }else if(filterCallAux.expired.id == 'noExpired'){
                    filterCallAux.expired = false;
                }else{
                    filterCallAux.expired = undefined;
                }
            }
            filterCallAux.query = false;
            return filterCallAux;
        }

        $scope.tableDefinition = {
            id: 'tableDossierTransactionView',
            origin: 'tram',
            objectType: 'DossierTransaction',
			callOrigin: 'annexa-gee-local',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.viewDossier = function (id) {
            $state.transitionTo('annexa.tram.pending.viewdossier', { "dossier" : id });
        }
        
        $scope.openDossier = function (id, idDt, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	$state.transitionTo('annexa.tram.pending.viewdossier', ({ dossier: id, dossierTransaction : idDt}));	            
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		$state.transitionTo('annexa.tram.pending.viewdossier', ({ dossier: id, dossierTransaction : idDt}));
        	}
        }

        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }

        $scope.initExp = function() {
            RegFactory.findFamiliesByRegisterClassification(undefined)
                .then(function(data) {
                    var familiesProcedure = JSOG.decode(data.data);
                    familiesProcedure = $linq(familiesProcedure).orderBy("x => x." + $scope.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                    var procedures = $linq(GlobalDataFactory.procedures).where("x => x.active == true").orderBy("x => x." + $scope.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                    var deletedProcedures = [];
                    _.forEach(procedures, function(value){
                        var profiles = $linq(value.procedureStartProfiles).intersect($rootScope.LoggedUser.userProfiles, "(x,y) => x.profile.id == y.profile.id").toArray();
                        if(!profiles || profiles.length == 0){
                            deletedProcedures.push(value);
                        }
                    });
                    procedures = $linq(procedures).except(deletedProcedures, "(x,y) => x.id == y.id").toArray();
                    
                    var modal = RegModals.initExpRegisterSelect;
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = { familyProcedure: {}, isPublic_true: true};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = { readOnly: false};
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.labelProp = Language.getActiveColumn();
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = CommonService.getTreeData(familiesProcedure, Language.getActiveColumn());
                    modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.entryClassification = undefined;
                    modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.procedures = procedures;
                    AnnexaFormlyFactory.showModal('modalGoToTram', modal, $scope.goToTram, false);

                })
        }
        
        $scope.goToTram = function () {
            $state.transitionTo('annexa.tram.newdossier', { "procedure": this.annexaFormly.model.modal_body.procedure });
            this.close();
        }
    }])
    .controller('TramViewController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory) {
        var dossierStatuses = [
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') },
            { id: 'CLOSED', name: $filter('translate')('CLOSED') } 
        ];

        var tramitationEstates = [
            { id: 'TRAMITATION', name: $filter('translate')('global.literals.tramParticipated') },
            { id: 'SEE', name: $filter('translate')('global.literals.tramPendingSee') }
        ];
        
        var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
            delete x['userProfiles'];
            delete x['ficticio'];
        });

        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, favorite: '', langColumn: $scope.languageColumn, query: true };

        $scope.tfilter = [
            { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dossierNumber')},
            { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','family')},
            { id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','procedure'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','procedure')},
            { id: 'procedureClassification', type: 'text', order: 0, label: 'global.literals.classification', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','procedureClassification'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','procedureClassification')},
            { id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','subject')},
            { id: 'thirds', type: 'text', order: 3, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','thirds')},
            { id: 'transaction', type: 'text', order: 5, label: 'global.literals.transaction', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','transaction'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','transaction')},
            { id: 'custom_fields_search', type: 'text', order: 6, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','custom_fields_search')},
            { id: 'dates', type: 'dateRange', order: 7, label: 'global.delegate.startDate', callAux: true , preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dates'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dates')},
            { id: 'dossierResponsibleUser', type: 'text', order: 8, label: 'global.tram.literals.dossierResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dossierResponsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dossierResponsibleUser')},
            (
                ($rootScope.esetMode)?
                { id: 'responsibleUser', type: 'text', order: 9, label: 'global.literals.responsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','responsibleUser')}
                    :
                { id: 'creator', type: 'text', order: 9, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','creator')}
            ),
            { id: 'register_entry', type: 'text', order: 10, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','register_entry')},
            { id: 'dossierStatus', type: 'select', order: 11, label: 'global.literals.state', dataType: 'LOCAL', data: angular.copy(dossierStatuses), addAll: true, nameProperty: 'name', callAux: true , preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dossierStatus',dossierStatuses,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dossierStatus')},
            { id: 'responsibleDossierProfile', type: 'select-multiple', order: 11, label: 'global.literals.responsiblesDossierProfiles', dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','responsibleDossierProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','responsibleDossierProfile')},
            { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','address') },
			{ id: 'show_archived', type: 'checkbox', order: 13, label: 'global.archive.archiveds', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','show_archived')}
            
        ];
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }

        var prefilter = undefined;
        if($stateParams.type){
            prefilter = {};
            var filterDossierStatus = $linq($scope.tfilter).where("x => x.id == 'dossierStatus'").toArray();
            if($stateParams.type == 'tramitation'){
            }else if($stateParams.type == 'finally' && filterDossierStatus){
                filterDossierStatus[0].preFilter = 'FINISHED';
                prefilter.dossierStatus = {value:'FINISHED'};
            }else if($stateParams.type == 'participated'){
            }else if($stateParams.type == 'favorit'){
                $scope.filterData.favorite = $stateParams.type;
            }
        }
        
        $scope.FavoritesColumnRenderFunction = function (data) {
            if(data) {
                var users = $linq(data)
                    .where("x => x.user.id == " + $rootScope.LoggedUser.id)
                    .toArray();
                if (users.length != 0) {
                    return 'favorit';
                } else {
                    return 'nofavorit';
                }
            }
            return '';
        };
        
        $scope.FavoritesColumnModificar = function(id) {
        	TramFactory.getDossier(id).then(function(data){
        		var idDossierTransaction = 1;
        		var actualDossierTransaction = $linq(TramFactory.Dossier.dossierTransactions)
        			.where("x => x.actual == true").toArray();
        		if(actualDossierTransaction != null && actualDossierTransaction.lenght != null && actualDossierTransaction.lenght != 0){
        			idDossierTransaction = actualDossierTransaction[0].id;
        		} else {
        			idDossierTransaction = TramFactory.Dossier.dossierTransactions[0].id;
        		}
        		TramFactory.modifyFavorite(idDossierTransaction, $rootScope.LoggedUser.id).then(function(data){
                    $scope.tableDefinition.reloadInternalData(false,true);
                }).catch(function(error){
                    $scope.tableDefinition.reloadInternalData(false, true);
                });
            }).catch(function(error){
                $scope.tableDefinition.reloadInternalData(false, true);
            });
        }
        
        $scope.FavoritesColumn = new IconSearchColumn(
                'favoriteUsers',
                '<iconsearchcolumn definition="FavoritesColumn" filterModel="filterData" fieldName="favorite"/>',
                '5%',
                new IconSearchRender($scope, 'FavoritesColumn'),
                false,
                true,
                [
                    new IconSearchOption('favorit','favorit', 'fa-star text-warn text-lg', 'fa-star text-warn text-lg', 'global.literals.favorite'),
                    new IconSearchOption('nofavorit','nofavorit', 'fa-star-o text-lg', 'fa-star-o text-lg', 'global.literals.noFavorite'),
                ],
                'tableDefinition',
                $scope.FavoritesColumnRenderFunction,
                'FavoritesColumnModificar'
            );
        
        var literalDays = !$rootScope.esetMode ? 'global.literals.days' : 'global.literals.dossierDays';

        var DaysColumn = new RemaingDaysColumn($filter, literalDays, HelperService);
        var StatusColumn = new DossierStatusColumn($filter, 'global.literals.dossier');
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn);
        var TranCounterColumn = new TransactionsCounterColumn();
        var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
        var DossierTransactionActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','openDossier([data], [bigDossier])',undefined,false,function(data, type, full, meta){
        	if(full && full.dossierStatus && _.contains(['ARCHIVED','REJECTED','TRANSFER'], full.dossierStatus)){
        		return false;
        	}else{
        		return true;
        	}
        }, undefined, ['bigDossier']),undefined,'id');
        var PrincipalColumn = new PrincipalThirdColumn($filter,'global.literals.principalDest');
        var CreatedDateColumn = new DateColumn($filter, 'global.literals.creation_date');
       	var dossierResponsibleColumn = new DossierResponsibleColumn($filter, 'global.tram.literals.dossierResponsible');
		var addressColumn = new AddressesColumn($filter('translate')('global.territory.list.address'), $scope.languageColumn);
        var RelatedColumn = new RelatedDossiersColumn();
		var profilesColumn = new ProfilesColumn($filter('translate')('global.literals.responsiblesDossierProfiles'), $scope.languageColumn);
        var SubjectColumn = new DossierSubjectColumn($filter, 'global.literals.subject', $scope.languageColumn);
        
        var DossierTransactionStatusRender = function(data, type, full, meta) {
            var content = '';

            if(data) {
                content = '<i class="text-success fa fa-circle" title="' + $filter('translate')('global.literals.closedMS') + '" aria-hidden="true"></i> ';
            } else {
                content = '<i class="text-warn fa fa-circle" title="' + $filter('translate')('global.literals.openedMS') + '" aria-hidden="true"></i> ';
            }

            return content;
        }

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            return filterCall;
        }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.filterOrigin = 'dossierView';
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.favorite = $scope.filterData.favorite;
            return filterCallAux;
        }

        var columnsAux = [
        	{ id: 'favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender(), sortable: false},
            { id: 'dossierStatus', width: '50px', column: StatusColumn },
            { id: 'remainingDays', width: '40px', column: DaysColumn },
            { id: 'dossierNumber', width: '140px', title: $filter('translate')('global.literals.code') },
            { id: 'procedure.' + ProcedureColumn.getColumn(), width: '140px', title:ProcedureColumn.getTitle()},
            { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
            { id: 'subject', width: '150px', title: SubjectColumn.getTitle(),  render: SubjectColumn.getRender },
            { id: 'principalThird', width: '200px', column: PrincipalColumn, sortable: false },
            { id: 'responsibleUser.name', width: '110px', column: dossierResponsibleColumn},
            { id: 'creatorUser', width: '150px', column: CreatorUserColumn},
            { id: 'createdDate', width: '100px', column:  CreatedDateColumn},
            { id: 'dossierResponsibleProfiles', width: '110px', column: profilesColumn, sortable: false },
            { id: 'relatedDossiersNumber', width: '40px', render: RelatedColumn.getRender, sortable: false },
			{ id: 'addresses',  column: addressColumn, sortable: false },
            { id: 'actions', columnName: 'id', width: '40px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_view', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_view', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossier.dossierNumber', sort: [[7, 'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
            id: 'tableDossierView',
            origin: 'tram',
            objectType: 'Dossier',
            callOrigin: 'annexa-gee-local',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.openDossier = function (id, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	$state.transitionTo('annexa.tram.view.viewdossier', ({ dossier: id }));	            
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		$state.transitionTo('annexa.tram.view.viewdossier', ({ dossier: id }));
        	}
        }

        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }
    }])
    .controller('TramResponsableController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory) {
        var dossierStatuses = [
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') }
        ];

        var tramitationEstates = [
            { id: 'TRAMITATION', name: $filter('translate')('global.literals.tramParticipated') },
            { id: 'SEE', name: $filter('translate')('global.literals.tramPendingSee') }
        ];
        
        var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
        	delete x['userProfiles'];
        	delete x['ficticio'];
        });

        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, favorite: '', langColumn: $scope.languageColumn, query: true };

        $scope.tfilter = [
            { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','dossierNumber')},
            { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','family')},
            { id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','procedure'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','procedure')},
            { id: 'procedureClassification', type: 'text', order: 0, label: 'global.literals.classification', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','procedureClassification'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','procedureClassification')},
            { id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','subject')},
            { id: 'thirds', type: 'text', order: 3, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','thirds')},
            { id: 'transaction', type: 'text', order: 5, label: 'global.literals.transaction', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','transaction'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','transaction')},
            { id: 'custom_fields_search', type: 'text', order: 6, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','custom_fields_search')},
            { id: 'dates', type: 'dateRange', order: 7, label: 'global.delegate.startDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','dates') , negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','dates')},
            (
                ($rootScope.esetMode)?
                { id: 'responsibleUser', type: 'text', order: 8, label: 'global.literals.responsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','responsibleUser')}
                    :
                { id: 'creator', type: 'text', order: 8, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','creator')}
            ),
            { id: 'register_entry', type: 'text', order: 9, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','register_entry')},
            { id: 'responsibleUserTram', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','responsibleUserTram'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','responsibleUserTram')},
            { id: 'responsibleTramitProfile', type: 'select-multiple', order: 11, label: 'global.literals.responsibleTramitProfile', dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','responsibleTramitProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','responsibleTramitProfile')},
            { id: 'address', type: 'text', order: 11, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','address') }
        ];
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }

        var prefilter = undefined;
        if($stateParams.type){
            prefilter = {};
            var filterDossierStatus = $linq($scope.tfilter).where("x => x.id == 'dossierStatus'").toArray();
            if($stateParams.type == 'tramitation'){
                //$scope.tfilter.tramitation.model = {id:'SEE'};
                //prefilter.tramitation = {value:'SEE'};
            }else if($stateParams.type == 'finally' && filterDossierStatus){
                filterDossierStatus[0].preFilter = 'FINISHED';
                prefilter.dossierStatus = {value:'FINISHED'};
            }else if($stateParams.type == 'participated'){
                //$scope.tfilter.tramitation.model = {id:'TRAMITATION'};
                //prefilter.tramitation = {value:'TRAMITATION'};
            }else if($stateParams.type == 'favorit'){
                $scope.filterData.favorite = $stateParams.type;
            }
        }

        $scope.FavoritesColumnRenderFunction = function (data) {
            if(data) {
                var users = $linq(data)
                    .where("x => x.user.id == " + $rootScope.LoggedUser.id)
                    .toArray();
                if (users.length != 0) {
                    return 'favorit';
                } else {
                    return 'nofavorit';
                }
            }
            return '';
        };
        
        $scope.FavoritesColumnModificar = function(id) {
        	TramFactory.getDossier(id).then(function(data){
        		var idDossierTransaction = 1;
        		var actualDossierTransaction = $linq(TramFactory.Dossier.dossierTransactions)
        			.where("x => x.actual == true").toArray();
        		if(actualDossierTransaction != null && actualDossierTransaction.lenght != null && actualDossierTransaction.lenght != 0){
        			idDossierTransaction = actualDossierTransaction[0].id;
        		} else {
        			idDossierTransaction = TramFactory.Dossier.dossierTransactions[0].id;
        		}
        		TramFactory.modifyFavorite(idDossierTransaction, $rootScope.LoggedUser.id).then(function(data){
                    $scope.tableDefinition.reloadInternalData(false,true);
                }).catch(function(error){
                    $scope.tableDefinition.reloadInternalData(false, true);
                });
            }).catch(function(error){
                $scope.tableDefinition.reloadInternalData(false, true);
            });
        }
        
        $scope.FavoritesColumn = new IconSearchColumn(
                'favoriteUsers',
                '<iconsearchcolumn definition="FavoritesColumn" filterModel="filterData" fieldName="favorite"/>',
                '5%',
                new IconSearchRender($scope, 'FavoritesColumn'),
                false,
                true,
                [
                    new IconSearchOption('favorit','favorit', 'fa-star text-warn text-lg', 'fa-star text-warn text-lg', 'global.literals.favorite'),
                    new IconSearchOption('nofavorit','nofavorit', 'fa-star-o text-lg', 'fa-star-o text-lg', 'global.literals.noFavorite'),
                ],
                'tableDefinition',
                $scope.FavoritesColumnRenderFunction,
                'FavoritesColumnModificar'
            );
        
        var literalDays = !$rootScope.esetMode ? 'global.literals.days' : 'global.literals.dossierDays';

        var DaysColumn = new RemaingDaysColumn($filter, literalDays, HelperService);
        var StatusColumn = new DossierStatusColumn($filter, 'global.literals.dossier');
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn);
        var TranCounterColumn = new TransactionsCounterColumn();
        var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
        var DossierTransactionActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','openDossier([data], [bigDossier])',undefined,false,undefined, undefined, ['bigDossier']),undefined,'id');
        var PrincipalColumn = new PrincipalThirdColumn($filter,'global.literals.principalDest');
        var CreatedDateColumn = new DateColumn($filter, 'global.literals.creation_date');
		var addressColumn = new AddressesColumn($filter('translate')('global.territory.list.address'), $scope.languageColumn);
        var SubjectColumn = new DossierSubjectColumn($filter, 'global.literals.subject', $scope.languageColumn);
        var RelatedColumn = new RelatedDossiersColumn();
        
        var DossierTransactionStatusRender = function(data, type, full, meta) {
            var content = '';

            if(data) {
                content = '<i class="text-success fa fa-circle" title="' + $filter('translate')('global.literals.closedMS') + '" aria-hidden="true"></i> ';
            } else {
                content = '<i class="text-warn fa fa-circle" title="' + $filter('translate')('global.literals.openedMS') + '" aria-hidden="true"></i> ';
            }

            return content;
        }

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            return filterCall;
        }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.filterOrigin = 'dossierView';
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.responsibleFilter = true;
            filterCallAux.favorite = $scope.filterData.favorite;
            filterCallAux.dossierStatus = {id:"IN_PROGRESS"};
            return filterCallAux;
        }

        var columnsAux = [
        	{ id: 'favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender(), sortable: false},
            { id: 'dossierStatus', width: '50px', column: StatusColumn },
            { id: 'remainingDays', width: '40px', column: DaysColumn },
            { id: 'dossierNumber', width: '140px', title: $filter('translate')('global.literals.code') },
            { id: 'procedure.' + ProcedureColumn.getColumn(), width: '140px', title:ProcedureColumn.getTitle()},
            { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
            { id: 'subject', width: '150px', title: SubjectColumn.getTitle(),  render: SubjectColumn.getRender },
            { id: 'principalThird', width: '200px', column: PrincipalColumn, sortable: false },
            { id: 'creatorUser', width: '150px', column: CreatorUserColumn},
            { id: 'createdDate', width: '100px', column:  CreatedDateColumn},
            { id: 'relatedDossiersNumber', width: '40px', render: RelatedColumn.getRender, sortable: false },
			{ id: 'addresses', column: addressColumn , sortable: false},
            { id: 'actions', columnName: 'id', width: '40px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_view', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_view', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossier.dossierNumber', sort: [[7, 'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
            id: 'tableDossierResponsible',
            origin: 'tram',
            objectType: 'Dossier',
            callOrigin: 'annexa-gee-local',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };
        $scope.openDossier = function (id, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	$state.transitionTo('annexa.tram.responsable.viewdossier', ({ dossier: id }));	            
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		$state.transitionTo('annexa.tram.responsable.viewdossier', ({ dossier: id }));
        	}
        }

        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }
    }])
    .controller('TramViewDossierViewController',['$scope', '$rootScope', '$state', '$stateParams', '$filter', 'TramFactory', 'Language', 'HeaderService', 'tramForms', '$timeout', 'CustomFieldFactory', 'HelperService', 'BoxFactory', 'AnnexaEntityFactory', 'globalModals', 'AnnexaFormlyFactory', 'AnnexaPermissionsFactory', 'DTColumnBuilder', 'CommonService', 'TableFilter', 'DccumentsFactory', 'NotificationFactory', 'SignLocalFactory', 'RegFactory', 'GlobalDataFactory', 'apiAdmin', function($scope, $rootScope, $state, $stateParams, $filter, TramFactory, Language, HeaderService, tramForms, $timeout, CustomFieldFactory, HelperService, BoxFactory, AnnexaEntityFactory, globalModals, AnnexaFormlyFactory, AnnexaPermissionsFactory, DTColumnBuilder, CommonService, TableFilter, DccumentsFactory, NotificationFactory, SignLocalFactory, RegFactory, GlobalDataFactory, apiAdmin) {
        $rootScope.showLoadingdivSignin = false;
        $scope.userSections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
        $scope.idDossierSelectedTab = 'right';
        $scope.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
        $scope.dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
        $scope.docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray()
        $scope.operationsAndInvoices = TramFactory.dossierOperationsAndInvoices;
        $scope.operationsActive = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active)?true:false);
        $scope.showId = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_id_dossier && $rootScope.app.configuration.show_id_dossier.value)?true:false);
        $scope.operationsActiveInDossier = (($scope.operationsActive && TramFactory.Dossier && TramFactory.Dossier.procedure && TramFactory.Dossier.procedure.operationsActive)?true:false);
		$scope.rightAccess = TramFactory.rightAccess;      
        $scope.rightAccesses = TramFactory.rightAccesses;
		$scope.viewdata = {
            tramTemplates: TramFactory.TramTemplates,
            documentTypes: GlobalDataFactory.documentTypes
        };

        $scope.dossierTramitationTypes = GlobalDataFactory.dossierTramitationTypes;
        $scope.tramClassifications = GlobalDataFactory.tramClassifications;
        $scope.allProfiles = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();

        $scope.dossier = TramFactory.Dossier;
		$scope.pricipalThird = undefined;
		
		$scope.$on('dossierSetReciverPrincipal', function (event, args) {
    	    if(args && args.dossier && args.dossier == $scope.dossier.id){ 
				if(args.third && args.third.third) {
    	    		$scope.pricipalThird = args.third.third;
				}else{
					$scope.pricipalThird = undefined;
				}
            }
        });

		
		$scope.modify = false;
        
        $scope.responsibleUserAux =  { user:undefined};
        if($scope.dossier.responsibleUser){
            var user_name = '';
            user_name = $scope.dossier.responsibleUser.name + ' ' + $scope.dossier.responsibleUser.surename1 +  ($scope.dossier.responsibleUser.surename2 ? ' ' +$scope.dossier.responsibleUser.surename2 : '');
            $scope.responsibleUserAux = {user: {id: $scope.dossier.responsibleUser.id, user: $scope.dossier.responsibleUser, value: user_name}};
        }

        $scope.dossierResponsibleProfilesAux = [];
        if($scope.dossier && $scope.dossier.dossierResponsibleProfiles && $scope.dossier.dossierResponsibleProfiles.length > 0){
            angular.forEach($scope.dossier.dossierResponsibleProfiles, function(value){
                $scope.dossierResponsibleProfilesAux.push(value.profile);
            })
        }
        
        $scope.printDossierResponsibleUser = function(){            	  
            if($scope.dossier && $scope.dossier.responsibleUser){
                var responsibleUser = "";
                if ($scope.dossier.responsibleUser.name) {
              	  responsibleUser = responsibleUser + $scope.dossier.responsibleUser.name + " ";
                }
                if ($scope.dossier.responsibleUser.surename1) {
              	  responsibleUser = responsibleUser + $scope.dossier.responsibleUser.surename1 + " ";
                }
                if ($scope.dossier.responsibleUser.surename2) {
              	  responsibleUser = responsibleUser + $scope.dossier.responsibleUser.surename2;
                }
                
                return responsibleUser;
            }else{
                return "";
            }
        }
        
        $scope.loadUsers = CommonService.loadUsers;
        $scope.archiveClassifications = GlobalDataFactory.archiveClassifications;
        if($scope.dossier) {
            $scope.isUpdateDossier = false;            
            $scope.canEditEsetDossierResponsibleUser = false;
            $scope.requiredTramClassification = false;
            $scope.showTramClassification = false;
            
            if($rootScope.app.configuration.tram_classification_dossier) {
            	$scope.requiredTramClassification = $rootScope.app.configuration.tram_classification_dossier.required;
            	$scope.showTramClassification = $rootScope.app.configuration.tram_classification_dossier.show;
            }
            
            $scope.receiverBox = AnnexaEntityFactory.getEditTramBox($scope.isUpdateDossier, 'receiver');

            var regInput = [];
            var regOutput = [];
            if($scope.dossier.relatedEntries){
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
            			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'asc') {
            		regInput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'INPUT'").orderBy("x => x.registerDate").toArray();
                	regOutput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'OUTPUT'").orderBy("x => x.registerDate").toArray();
            	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
            			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'desc') {
            		regInput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'INPUT'").orderByDescending("x => x.registerDate").toArray();
                	regOutput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'OUTPUT'").orderByDescending("x => x.registerDate").toArray();
            	} else {
            		regInput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'INPUT'").toArray();
                	regOutput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'OUTPUT'").toArray();
            	}
            }
            $scope.registerEntryInputBox = {
                boxTitle: 'global.literals.reg_input',
                permissions: {},
                content:  regInput,
                config: {},
                origin: 'tram-view'
            }

            $scope.registerEntryOutputBox = {
                boxTitle: 'global.literals.reg_output',
                permissions: {},
                content:  regOutput,
                config: {},
                origin: 'tram-view'
            }

            //endregion
            $scope.dossierBox = AnnexaEntityFactory.getEditTramBox($scope.isUpdateDossier, 'dossier');
            
            if($scope.dossier.notifications){
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_notification_dossier && 
            			$rootScope.app.configuration.order_box_notification_dossier.value && $rootScope.app.configuration.order_box_notification_dossier.value == 'asc') {
            		$scope.dossier.notifications = $linq($scope.dossier.notifications).orderBy("x => x.createdDate").toArray();
            	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_notification_dossier && 
            			$rootScope.app.configuration.order_box_notification_dossier.value && $rootScope.app.configuration.order_box_notification_dossier.value == 'desc') {
            		$scope.dossier.notifications = $linq($scope.dossier.notifications).orderByDescending("x => x.createdDate").toArray();
            	}
            }
            $scope.notificationBox = {
                permissions: {},
                search: {},
                content:  $scope.dossier.notifications,
                new: {},
                config: {dossier: $scope.dossier},
                origin: 'tram-view'
            }
            $scope.operationsAndInvoicesBox = {
            	boxTitle: 'global.operationsAndInvoices.title',
                permissions: {},
                content:  $scope.operationsAndInvoices ?  $scope.operationsAndInvoices : [],
                config: { dossier: $scope.dossier },
                origin: 'tram-view'
            };
            $scope.addressBox = {
                permissions: {},
                content:  $scope.dossier.addresses,
                config: {},
                origin: 'tram-view'
            }
			var dossierCFs = (($scope.dossier.customFields)?$scope.dossier.customFields : []);  
			if(dossierCFs){
				dossierCFs = $linq(dossierCFs).where("x => !x.hiddenField").toArray();
			}
			$scope.dossierCustomFieldsBox = {
            	boxTitle: 'global.literals.customFields',
                permissions: {},
                content:  dossierCFs,
                config: { dossier: $scope.dossier },
                origin: 'tram-view'
            };
			var adminRightAccess = false;
			if($state.current.name == 'annexa.archive.finally.see' || $state.current.name == 'annexa.archive.close.see'){
        		if(!($scope.dossier && $scope.dossier.dossierStatus == 'ARCHIVED' && $scope.dossier.archiveDefinitiveDate)){
                	adminRightAccess = true;
                }
        	}
			$scope.rightAccessBox = {
            	boxTitle: 'global.archive.accessRights',
                permissions: ((adminRightAccess)?{ new: ['admin_access_rights'] }:{}),
                search: {},
                content:  $scope.rightAccesses,
                new: ((adminRightAccess)?{newFunc: $scope.addAccessRight}:{}),
                config: { dossier: $scope.dossier },
                origin: 'tram-view',
				canShow: ((adminRightAccess && AnnexaPermissionsFactory.havePermission(['admin_access_rights']))?true:false)
            };
			
			var sessions = [];
            if (TramFactory.proposalsDossierTransaction && TramFactory.proposalsDossierTransaction.length > 0) {
            	 angular.forEach(TramFactory.proposalsDossierTransaction, function (pdt) {
            		 if(pdt.proposal && pdt.proposal.organs && pdt.proposal.organs.length > 0) {
                		 angular.forEach(pdt.proposal.organs, function (organ) {
                			 if(organ && organ.session && organ.session.id && !$linq(sessions).contains(organ.session, "(x, y) => x.id == y.id")) {
                        		 sessions.push(organ.session);
                			 }
                    	 });
            		 }
            	 });
              }
            
            $scope.sessionBox = {
                    content: sessions,
                    config: {},
                    origin: 'annexa-document',
                    isEdit: false
                }
            
            var decrees = [];
            if (TramFactory.proposalsDecree && TramFactory.proposalsDecree.length > 0) {
            	var decrees = TramFactory.proposalsDecree;
            }
            
            $scope.decreeBox = {
                    content: decrees,
                    config: {},
                    origin: 'annexa-document',
                    isEdit: false
                }
			
            var relatedDossiers =  (($scope.dossier && $scope.dossier.relatedDossiers)?angular.copy($scope.dossier.relatedDossiers):[]);
            $scope.dossierBox.boxDefinition.content = [];
            _.forEach(relatedDossiers, function(relDos){
            	if(relDos.actual && relDos.actual.id == $scope.dossier.id){
            		relDos.inverse = false;
            		$scope.dossierBox.boxDefinition.content.push(relDos);
            	}else{
            		var daux = angular.copy(relDos.actual);
            		relDos.actual = relDos.related;
            		relDos.related =daux;
            		relDos.inverse = true;
            		$scope.dossierBox.boxDefinition.content.push(relDos);
            	}
            });

            $scope.dossierAuditBox = {
                content: angular.copy(TramFactory.archiveAuditInfo),
                show: _.contains(['CLOSED', 'TRANSFER', 'REJECTED', 'ARCHIVED'],$scope.dossier.dossierStatus)
            }
            
            //$scope.dossierBox.boxDefinition.content = $scope.dossier.relatedDossiers;
            $scope.notificationBox.content = $scope.dossier.notifications;
            if ($scope.dossier.thirds && $scope.dossier.thirds.length > 0) {
                angular.forEach($scope.dossier.thirds, function (value, key) {
					if(value.principal){
						$scope.pricipalThird = angular.copy(value.third);
					}
                    var dossierThird = new ThirdAddress(value.third, $scope.languageColumn, $scope.dossier);
                    $scope.receiverBox.boxDefinition.content.push({
                        id: value.id,
                        third: value.third,
                        interested: value.interested,
                        representative: value.representative,
                        principal: value.principal,
                        addresses: dossierThird.getAdressesHtml(),
                        representated: value.representated,
                        roleInterested: value.roleInterested
                    });
                });
            }

            var boxDocuments = $linq($scope.dossier.dossierTransactions).select("x => x.documents").toArray();
            boxDocuments = [].concat.apply([], boxDocuments)
            boxDocuments = $linq(boxDocuments).select("x => x.document").toArray();
            $scope.boxes = [ $scope.receiverBox, $scope.registerInputBox, $scope.registerOutputBox, $scope.notificationBox, { boxDefinition: { title: 'global.literals.documents', content: boxDocuments } }, $scope.operationsAndInvoicesBox, $scope.rightAccessBox];

            $scope.showview = false;
            $scope.daysexpire = 0;
            $scope.expiredate = '';

            $scope.tabs = {
                principal: {
                    left: './views/layout/viewdossier/general_left.html',
                    center: './views/layout/viewdossier/general_center.html',
                    right: './views/layout/viewdossier/general_right.html'
                }
            }

            var calculateTransaction = function () {
                // TODO: no agafar la primera!
                var actualTransaction = $linq($scope.dossier)
                    .join($scope.dossier.dossierTransactions, "x => x.id", "x => x.dossier.id", "(outer, inner) => inner")
                    .where("x => x.actual == true")
                    .toArray();

                if (actualTransaction.length != 0) {
                    $scope.actualTransaction = actualTransaction[0];
                    $scope.transactions.push($scope.actualTransaction);
                } else {
                    $scope.actualTransaction = {};
                    $scope.actualTransaction[$scope.languageColumn] = '';
                }


                var pastTransactions = $linq($scope.dossier)
                    .join($scope.dossier.dossierTransactions, "x => x.id", "x => x.dossier.id", "(outer, inner) => inner")
                    .where("x => x.endDate != null && x.actual == false")
                    .orderByDescending("x => x.endDate")
                    .toArray();

                if (pastTransactions.length != 0) {
                    angular.forEach(pastTransactions, function (value, key) {
                        $scope.transactions.push(value);
                    });
                }
            };

            $scope.getProfileNames = function(transaction){
                var profiles = '';
                if(transaction && transaction.processProfiles){
                    _.forEach(transaction.processProfiles, function(processProfile){
                        if(processProfile && processProfile.profile){
                            if(profiles == ''){
                                profiles += processProfile.profile[$scope.languageColumn];
                            }else{
                                profiles += ', '+processProfile.profile[$scope.languageColumn];
                            }
                        }
                    });
                }
                return profiles;
            }

            $scope.transactions = $linq($scope.dossier.dossierTransactions).orderByDescending("x => x.createdDate").thenByDescending("x => x.id").toArray();
            $scope.activeTransaction = 0;

            if($scope.transactions && $scope.transactions.length > 0) {
                $scope.actualTransaction = undefined;
                _.forEach($scope.transactions, function (transaction, index) {
                    if(AnnexaPermissionsFactory.canViewOrTramitTransaction(transaction, $scope.dossier) && !$scope.actualTransaction) {
                        $scope.actualTransaction = transaction;
                        $scope.dossierTransactionId = transaction.id;
                        $scope.idTransactionSelectedTab = transaction.id;
                        $scope.activeTransaction = index;
                    }
                });

                if(!$scope.actualTransaction) {
                    $scope.actualTransaction = {};
                    $scope.actualTransaction[$scope.languageColumn] = '';
                    $scope.idTransactionSelectedTab = undefined;
                }
            } else {
                $scope.actualTransaction = {};
                $scope.actualTransaction[$scope.languageColumn] = '';
                $scope.idTransactionSelectedTab = undefined;
            }

            var endDate = new Date();
            if($scope.dossier.endDate){
                endDate = new Date($scope.dossier.endDate);
            }

            if(!$scope.actualTransaction.documents){
                $scope.actualTransaction.documents = [];
            }

            $scope.daysexpire = $scope.dossier.remainingDays;
            $scope.expireType = "BUSINESS";
            if($scope.dossier.expireType != null){
            	$scope.expireType = $scope.dossier.expireType;
            } else if($scope.dossier.procedure && $scope.dossier.procedure.expireType != null) {
            	$scope.expireType = $scope.dossier.procedure.expireType;
            }
            $scope.expiredate = HelperService.getExpirationDate(endDate, $scope.daysexpire, $rootScope.LoggedUser.entity.holidays, $scope.expireType);

            HeaderService.changeState($state.current);

            $scope.showview = true;

            $scope.backbutton = ($scope.transactions.length < 2) ? true : false;

            if (!$scope.backbutton) {
                $scope.previous = $scope.transactions[1];
            }
            else {
                $scope.previous = $scope.actualTransaction;
            }
        }else{
            $state.transitionTo('annexa.tram.pending');
        }
        $scope.actionsReg = function() {
            alert('Registre accions');
        };

        $scope.cancelDossierModal = function () {
            var modal = globalModals.cancelDossier;
            modal.annexaFormly.model = {};
            modal.annexaFormly.options = {};
            AnnexaFormlyFactory.showModal('modalSection', modal, $scope.cancelDossier, false);

        }

        $scope.cancelDossier = function (reason) {
            var modal = this;

            if(reason.annexaFormly.model.modal_body && reason.annexaFormly.model.modal_body.reason) {
                TramFactory.cancelDossier(reason.annexaFormly.model.modal_body.reason)
                    .then(function (data) {
                        modal.close();
                        $state.transitionTo('annexa.tram.pending');
                    }).catch(function (error) {
                });
            }
        };

        $scope.updateDossier = function (data, property) {
            var dossierAux = angular.copy($scope.dossier);
            if(property == 'archiveClassification'){
                if(data && data.$selected && data.$selected.id){
                    dossierAux[property] = {id:data.$selected.id};
                }else{
                    dossierAux[property] = {};
                }
            }else{
            	dossierAux.archiveClassification = ((TramFactory.DossierArchiveClassification)?TramFactory.DossierArchiveClassification : {});
                dossierAux[property] = data;
            }
            TramFactory.updateDossier(dossierAux, true)
                .then(function(data) {
                	var dos = JSOG.decode(data);
                	TramFactory.DossierArchiveClassification = dos.archiveClassification;
                    $scope.dossier.thirds = dos.thirds;
                }).catch(function (error) {
            });
        };

        $scope.generateFoliate = function(){
            TramFactory.generateFoliate();
        }

        $scope.addAccessRight = function(){
        	TramFactory.addAccessRight();
        }
                
        HeaderService.onChangeState($scope, function(message) {
        	if(message.state.name == 'annexa.tram.view.viewdossier') {
            	if(!$rootScope.subHeadButtons){
            		$rootScope.subHeadButtons = [];
            	}
                if($scope.dossier && $scope.dossier.closed) {
                    if(AnnexaPermissionsFactory.havePermission('reopen_dossier') || (!$rootScope.esetMode && TramFactory.isResponsible(TramFactory.Dossier))){
                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#view-dossier', 'fa-archive', 'global.literals.reopen', undefined, 'reopenTram'));
                    }
                    if($rootScope.app.configuration.show_dossier_foliate.value) {
                    	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#view-dossier',undefined,'global.literals.foliate',undefined,'generateFoliate'));
                    }
                }
                $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#view-dossier', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditDossier').setPermissions('admin_audit'));
            }
        });

        $scope.auditDossier = function () {
            if ($scope.dossier && $scope.dossier.id) {
                window.open($state.href('annexa.admin.audit_dossier', {"objectId": $scope.dossier.id }), '_blank');
            }
        };

        $scope.getProfileValue = function(){
            var prof = '';
            if($scope.dossierResponsibleProfilesAux && $scope.dossierResponsibleProfilesAux.length > 0){
                angular.forEach($scope.dossierResponsibleProfilesAux, function(value){
                    if(prof){
                        prof += ", "+value[$scope.languageColumn];
                    }else{
                        prof += value[$scope.languageColumn];
                    }
                })
            }
            return prof;
        };

        HeaderService.changeState($state.current);

        $scope.archive = function(){

        }

        $scope.reopenTram = function(){
            TramFactory.reopenTramModal();
        }

        $scope.getProfileValue = function(){
            var prof = '';
            if($scope.dossierResponsibleProfilesAux && $scope.dossierResponsibleProfilesAux.length > 0){
                angular.forEach($scope.dossierResponsibleProfilesAux, function(value){
                    if(prof){
                        prof += ", "+value[$scope.languageColumn];
                    }else{
                        prof += value[$scope.languageColumn];
                    }
                })
            }
            return prof;
        };

        $scope.printQdC = function(value) {
            var qdc = '';

            var getName = function(item, label) {

                if(label) {
                    label = ' / ' + label;
                }

                if(item){
                	label = ((item[$scope.languageColumn])?item[$scope.languageColumn]:((item.id)?item.id:'undefined')) + label;
	                if(item.parent) {
	                	label = getName(item.parent, label);
	                }
                }
                return label;
            }
            if(value && value.model && value.model.$selected && value.model.$selected.id) {
                var selectedQdc = $linq(GlobalDataFactory.archiveClassifications).singleOrDefault(undefined, "x => x.id == " + value.model.$selected.id);

                if(selectedQdc) {
                    qdc = getName(selectedQdc, qdc);
                }
            }else if(TramFactory.DossierArchiveClassification && TramFactory.DossierArchiveClassification.id){
           	 var selectedQdc = $linq(GlobalDataFactory.archiveClassifications).singleOrDefault(undefined, "x => x.id == " + TramFactory.DossierArchiveClassification.id);

                if(selectedQdc) {
                    qdc = getName(selectedQdc, qdc);
                }
           }

            return qdc;
        }
        
        $scope.printDossierUrgent = function(){
            if($scope.dossier && $scope.dossier.dossierUrgent){
                return $filter('translate')('global.literals.yes');
            }else{
                return $filter('translate')('global.literals.no');
            }
        }

        $scope.disableTransaction = function (transaction) {
            return false;
        };
        
        $scope.transactionDossierTabSelected = function (trans) {
        	if (trans && trans.transactionType 
        			&& trans.transactionType.transactionSubtype != null && trans.transactionType.transactionSubtype != undefined
            		&& !angular.equals(trans.transactionType.transactionSubtype, apiAdmin.transactionSubtypes[1].id)) {
        		$scope.activeTab = 0;
                $rootScope.$broadcast('transactionTabSelected', {tab: 0});
        	}
        	$scope.idTransactionSelectedTab = ((trans)?trans.id:undefined);
        }
        
        $scope.dossierSelectedTab = function(selected){
        	if(selected && selected == 'center'){
        		$scope.idDossierSelectedTab = selected;
        	}else if(selected && selected == 'left'){
        		$scope.idDossierSelectedTab = selected;
        		$rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounterReloadTable', { reload:true });
        	}else{
        		$scope.idDossierSelectedTab = selected;
        	}
        }
        
    }])
    .controller('TramViewDossierController',['$scope', '$rootScope', '$state', '$stateParams', '$filter', 'TramFactory', 'Language', 'HeaderService', 'tramForms', '$timeout', 'CustomFieldFactory', 'HelperService', 'BoxFactory', 'AnnexaEntityFactory', 'globalModals', 'AnnexaFormlyFactory', 'dialogs', 'ErrorFactory', 'ABMComponentFactory', 'ABMShapesFactory', 'DTColumnBuilder', 'TableFilter', 'DccumentsFactory', 'NotificationFactory', 'CommonService', 'AnnexaPermissionsFactory', 'SignLocalFactory', '$http', 'GlobalDataFactory', 'apiAdmin', 'DialogsFactory', 'TerritoryFactory', 'ThirdFactory', function($scope, $rootScope, $state, $stateParams, $filter, TramFactory, Language, HeaderService, tramForms, $timeout, CustomFieldFactory, HelperService, BoxFactory, AnnexaEntityFactory, globalModals, AnnexaFormlyFactory, dialogs, ErrorFactory, ABMComponentFactory, ABMShapesFactory, DTColumnBuilder, TableFilter, DccumentsFactory, NotificationFactory, CommonService, AnnexaPermissionsFactory, SignLocalFactory, $http, GlobalDataFactory, apiAdmin, DialogsFactory, TerritoryFactory, ThirdFactory) {
        $scope.esetMode = $rootScope.esetMode;
        $scope.userSections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
        $scope.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
        $scope.dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
        $scope.docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray()
        $scope.idDossierSelectedTab = 'right';
        $scope.activeTransaction = 0;
        $scope.operationsAndInvoices = TramFactory.dossierOperationsAndInvoices;
        $scope.userAccountInstances = TramFactory.userAccountInstances;
        $scope.operationsActive = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active)?true:false);
        $scope.showId = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_id_dossier && $rootScope.app.configuration.show_id_dossier.value)?true:false);
    	$scope.rightAccesses = TramFactory.rightAccesses;		
    	$scope.operationsActiveInDossier = (($scope.operationsActive && TramFactory.Dossier && TramFactory.Dossier.procedure && TramFactory.Dossier.procedure.operationsActive)?true:false);
        $scope.disableTransaction = function (transaction) {
            return false;
        }
        
        $scope.transactionDossierTabSelected = function (trans) {
        	if (trans && trans.transactionType 
        			&& trans.transactionType.transactionSubtype != null && trans.transactionType.transactionSubtype != undefined
        			&& !(angular.equals(trans.transactionType.transactionSubtype, apiAdmin.transactionSubtypes[1].id)
        					|| angular.equals(trans.transactionType.transactionSubtype, apiAdmin.transactionSubtypes[2].id)
        					|| angular.equals(trans.transactionType.transactionSubtype, apiAdmin.transactionSubtypes[3].id)
        					|| angular.equals(trans.transactionType.transactionSubtype, apiAdmin.transactionSubtypes[10].id))
        		) {
        		$scope.activeTab = 0;
                $rootScope.$broadcast('transactionTabSelected', {tab: 0});
        	}
            $scope.idTransactionSelectedTab = ((trans)?trans.id:undefined);
        }

        $scope.dossierSelectedTab = function(selected){
        	if(selected && selected == 'center'){
        		$scope.idDossierSelectedTab = selected;
        	}else if(selected && selected == 'left'){
        		$scope.idDossierSelectedTab = selected;
        		$rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounterReloadTable', { reload:true });
        	}else{
        		$scope.idDossierSelectedTab = selected;
        	}
        }


        $scope.dossierAlerts = []
        $rootScope.showLoadingdivSignin = false;

        $scope.viewdata = {
            tramTemplates: TramFactory.TramTemplates,
            documentTypes: GlobalDataFactory.documentTypes
        };

        $scope.dossierTramitationTypes = GlobalDataFactory.dossierTramitationTypes;
        $scope.tramClassifications = GlobalDataFactory.tramClassifications;
        
        $scope.comboYesNo = [
            { id: true, description: $filter('translate')('global.literals.yes')},
            { id: false, description: $filter('translate')('global.literals.no')}
            ];

        $scope.dossier = TramFactory.Dossier;
        
        var selectableOptions = [];
        _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
        	var haveProfile = false;
        	if($scope.dossier && $scope.dossier.dossierResponsibleProfiles) {
        		haveProfile = $scope.dossier.dossierResponsibleProfiles.some(function (resProfile) {
        			  return resProfile.profile.id === value.id;
        			});
        	}
            if(value.expiryDate == null || haveProfile) {
                selectableOptions.push(value);
            }
        });
        $scope.allProfiles = $linq(selectableOptions).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
        
        $scope.pricipalThird = undefined;
		$scope.modify = true;

		$scope.$on('dossierSetReciverPrincipal', function (event, args) {
        	    if(args && args.dossier && args.dossier == $scope.dossier.id){
					if(args.third && args.third.third) {
        	    		$scope.pricipalThird = args.third.third;
					}else{
						$scope.pricipalThird = undefined;
					}
                }
        });

        $scope.dossierTransactionId = $stateParams.dossierTransaction;
        $scope.archiveClassifications = GlobalDataFactory.archiveClassifications;
        $scope.procedureStartProfiles = TramFactory.procedureStartProfiles;
        var canEditEsetDossierResponsibleUser = function(){
        	var res = false;
        	
            if ($rootScope.esetMode && 
            		$scope.isUpdateDossier && 
            		$scope.procedureStartProfiles) {
            	
            	var procedureStartProfilesIds = $linq($scope.procedureStartProfiles).select("x => x.profile.id").toArray();
            	var userProfilesIds = $linq($scope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
            	
            	var profilesIntersect = $linq(procedureStartProfilesIds).intersect(userProfilesIds).toArray();
            	if (profilesIntersect.length > 0) {
            		res = true;
            	}
            }
            
            return res;
        };
        
        $scope.comboDossierResponsibleUser = [];                
        
        $scope.loadComboDossierResponsibleUser = function () {
        	$scope.comboDossierResponsibleUser = [];
        	var addedUsers = [];
            angular.forEach($scope.procedureStartProfiles, function (profile) {
                var users = $linq(profile.profile.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
                users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();

                angular.forEach(users, function (user) { 
                	if (!$scope.dossier.responsibleUser || user.id != $scope.dossier.responsibleUser.id) {
                		if(!_.contains(addedUsers, user.id)){
	                    	var responsibleUser = "";
	                        if (user.name) {
	                      	  responsibleUser = responsibleUser + user.name + " ";
	                        }
	                        if (user.surename1) {
	                      	  responsibleUser = responsibleUser + user.surename1 + " ";
	                        }
	                        if (user.surename2) {
	                      	  responsibleUser = responsibleUser + user.surename2;
	                        }
	                    	addedUsers.push(user.id);
	                    	$scope.comboDossierResponsibleUser.push({id: user.id, description: responsibleUser});
                		}
                	}
                });
            });
            if($scope.comboDossierResponsibleUser && $scope.comboDossierResponsibleUser.length > 0){
            	$scope.comboDossierResponsibleUser = $linq($scope.comboDossierResponsibleUser).orderBy("x => x.description", HelperService.caseInsensitiveOrNullComparer).toArray();
            }
            addedUsers = undefined;
        };   
        
        $scope.loadComboDossierResponsibleUser();
        
        $scope.printDossierResponsibleUser = function(){            	  
            if($scope.dossier && $scope.dossier.responsibleUser){
                var responsibleUser = "";
                if ($scope.dossier.responsibleUser.name) {
              	  responsibleUser = responsibleUser + $scope.dossier.responsibleUser.name + " ";
                }
                if ($scope.dossier.responsibleUser.surename1) {
              	  responsibleUser = responsibleUser + $scope.dossier.responsibleUser.surename1 + " ";
                }
                if ($scope.dossier.responsibleUser.surename2) {
              	  responsibleUser = responsibleUser + $scope.dossier.responsibleUser.surename2;
                }
                
                return responsibleUser;
            }else{
                return "";
            }
        }
        
        if($scope.dossier) {
            $scope.isUpdateDossier = true;
            if(!TramFactory.canUpdateDossier || ($scope.dossier.dossierStatus != 'IN_PROGRESS' && !TramFactory.isArchivedDossier) 
            		|| (($scope.dossier.dossierStatus != 'TRANSFER' && $scope.dossier.dossierStatus != 'REJECTED' && !($scope.dossier.dossierStatus == 'ARCHIVED' && !$scope.dossier.archiveDefinitiveDate)) && TramFactory.isArchivedDossier)){
                $scope.isUpdateDossier = false;
            }
            $scope.canEditEsetDossierResponsibleUser = canEditEsetDossierResponsibleUser();

            $scope.requiredTramClassification = false;
            $scope.showTramClassification = false;
            $scope.editQdcOnEsetMode = false;
            
            if($rootScope.app.configuration.tram_classification_dossier) {
            	$scope.requiredTramClassification = $rootScope.app.configuration.tram_classification_dossier.required;
            	$scope.showTramClassification = $rootScope.app.configuration.tram_classification_dossier.show;
            }
            
            if($rootScope.app.configuration.edit_qdc_eset) {
            	$scope.editQdcOnEsetMode = $rootScope.app.configuration.edit_qdc_eset.value;
            }

            $scope.receiverBox = AnnexaEntityFactory.getEditTramBox($scope.isUpdateDossier, 'receiver');
            $scope.dossierBox = AnnexaEntityFactory.getEditTramBox($scope.isUpdateDossier, 'dossier');

            var addNotificationSave = function(notifications) {
                if(notifications && notifications.length > 0) {
                    NotificationFactory.createMultipleNotifications(notifications)
                        .then(function(data) {
                            _.forEach(data, function (item) {
                                $scope.notificationBox.content.push(item);
                                if(item && item.registerEntry && $scope.registerEntryOutputBox && $scope.registerEntryOutputBox.content){
									var regExist = $linq($scope.registerEntryOutputBox.content).firstOrDefault(undefined, "x => x.id == "+item.registerEntry.id);
									if(!regExist){
                                    	$scope.registerEntryOutputBox.content.push(item.registerEntry);
									}
                                }
                                if($scope.notificationBox.content){
                                	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_notification_dossier && 
                                			$rootScope.app.configuration.order_box_notification_dossier.value && $rootScope.app.configuration.order_box_notification_dossier.value == 'asc') {
                                		$scope.notificationBox.content = $linq($scope.notificationBox.content).orderBy("x => x.createdDate").toArray();
                                	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_notification_dossier && 
                                			$rootScope.app.configuration.order_box_notification_dossier.value && $rootScope.app.configuration.order_box_notification_dossier.value == 'desc') {
                                		$scope.notificationBox.content = $linq($scope.notificationBox.content).orderByDescending("x => x.createdDate").toArray();
                                	}
                                }
                            });
                        }).catch(function (error) {
                        	if(error && error.data && error.data.message == 'No notification type'){
                        		DialogsFactory.error($filter('translate')('global.sec.errors.noNotificationType'));
                        	}else if(error && error.data && error.data.message == 'channelNoValidSendNotifications'){
                        		DialogsFactory.error($filter('translate')('global.errors.channelNoValidSendNotifications'));
							}else if(error && error.data && error.data.message == 'No documents to this third notification'){
                        		DialogsFactory.error($filter('translate')('global.errors.noDocumentsForThird'));
                        	}else if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
								var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
								DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
							}else if(error && error.data && error.data.message === "Is not valid document from template not found"){
								DialogsFactory.error($filter('translate')('global.documents.definitiveNotificationNoValidError'));
							}else{
                        		DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
                        	}
                        	console.error(error);
                    });
                }
            }
            
            var updateDouments = function(dossierTransaction) {
            	var transactionAux = $linq($scope.transactions).firstOrDefault(undefined, "x => x.id == "+dossierTransaction.id);
                if(transactionAux && dossierTransaction.documents){
                	transactionAux.documents = dossierTransaction.documents;
                }
            }

            if($scope.dossier.notifications){
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_notification_dossier && 
            			$rootScope.app.configuration.order_box_notification_dossier.value && $rootScope.app.configuration.order_box_notification_dossier.value == 'asc') {
            		$scope.dossier.notifications = $linq($scope.dossier.notifications).orderBy("x => x.createdDate").toArray();
            	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_notification_dossier && 
            			$rootScope.app.configuration.order_box_notification_dossier.value && $rootScope.app.configuration.order_box_notification_dossier.value == 'desc') {
            		$scope.dossier.notifications = $linq($scope.dossier.notifications).orderByDescending("x => x.createdDate").toArray();
            	}
            }
            $scope.notificationBox = {
                permissions: (($scope.isUpdateDossier)?{ new: ['new_output_entry_register']}:{}),
                search: { saveSearch: addNotificationSave },
                content:  $scope.dossier.notifications,
                new: {},
                config: { 	dossier: $scope.dossier,
                			dossierTransactionId: $scope.dossierTransactionId,
                			updateDocumentsFunction: updateDouments},
                origin: 'tram'
            }

            $scope.$on('created_multiple_notifications_from_document', function (event, args) {
        	    if($scope.notificationBox.search && $scope.notificationBox.search.saveSearch && args && args.parentIsModal=="isNotModal") {
        	    	$scope.notificationBox.search.saveSearch(args.notifications);
        	    	if($scope.notificationBox.content){
                    	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_notification_dossier && 
                    			$rootScope.app.configuration.order_box_notification_dossier.value && $rootScope.app.configuration.order_box_notification_dossier.value == 'asc') {
                    		$scope.notificationBox.content = $linq($scope.notificationBox.content).orderBy("x => x.createdDate").toArray();
                    	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_notification_dossier && 
                    			$rootScope.app.configuration.order_box_notification_dossier.value && $rootScope.app.configuration.order_box_notification_dossier.value == 'desc') {
                    		$scope.notificationBox.content = $linq($scope.notificationBox.content).orderByDescending("x => x.createdDate").toArray();
                    	}
                    }
                }
            });
            
            $scope.$on('annexaDocumentActionsButtonReload', function(event,args) {
            	if(args && args.doc && TramFactory.isArchivedDossier){
	            	var doc = args.doc;
	            	if(doc && TramFactory && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions){
	            		$rootScope.$broadcast('annexaDocumentActionsButtonReloadTable');
	            		_.forEach(TramFactory.Dossier.dossierTransactions, function(dt){
                    		if(dt.documents){
                    			_.forEach(dt.documents, function(docAux){
                    				if(docAux.document && doc.id == docAux.document.id){
                    					docAux.document.docStatus = doc.docStatus;
                    					docAux.document.name = doc.name;
                    				}
                    			});
                    		}
                    	});
	            	}
            	}
            });
            $rootScope.$on('new_notification', function(event, args) {
                if(args.notifications && args.notifications.length > 0) {
                    _.forEach(args.notifications, function(notification){
                        if(notification && notification.dossier && notification.dossier.relatedEntriesOutput) {
                            var relatedEntries = $linq(notification.dossier.relatedEntriesOutput).except($scope.dossier.relatedEntriesOutput, "(x,y) => x.id == y.id").toArray();
                            if (relatedEntries && relatedEntries.length > 0) {
                                _.forEach(relatedEntries, function(relatedEntry){
                                    $scope.dossier.relatedEntriesOutput.push(relatedEntry);
                                });
                            }
                        }
                    });
                }
            });
            
            var addAddressSave = function(addresses) {
                $http({
                    url: './api/tram/dossier/' + $scope.dossier.id + '/address/' + $linq(addresses).select("x => x.address && x.address.id").toArray(),
                    method: 'POST'
                }).then(function (data) {
                	$scope.addressBox.content.length =0;
                	var dataAux = JSOG.decode(data.data);
                	_.forEach(dataAux.addresses, function(address){
                		$scope.addressBox.content.push(address);
                	});
                }).catch(function(error) {
                    console.log(error);
                });
            };
            $scope.$on('annexaBoxAddressDelete', function (event, args) {
                if(args.origin && args.origin == 'tram') {
                	$http({
                        url: './api/tram/dossier/' + $scope.dossier.id + '/address/' + args.removedId,
                        method: 'DELETE'
                    }).then(function (data) {
                        //Empty
                    }).catch(function(error) {
                        console.log(error);
                    });
                 }
            });
            var addressBoxAux = {
                permissions: {},
                content:  $scope.dossier.addresses,
                config: {},
                origin: 'tram'
            }
			if($scope.isUpdateDossier){ 
	            addressBoxAux.permissions = { new: ['process_dossier'], view:['process_dossier']};
	            addressBoxAux.search = { placeholder: 'global.literals.placeholderSearchAddressBox', advanced: true, saveSearch: addAddressSave };
	            addressBoxAux.new = {};
			}
			$scope.addressBox = addressBoxAux;
            
            if(!TerritoryFactory.canCreateAddress()) {
            	$scope.addressBox.new = undefined;
            }
			var dossierCFs = (($scope.dossier.customFields)?$scope.dossier.customFields : []);  
			if(dossierCFs){
				dossierCFs = $linq(dossierCFs).where("x => !x.hiddenField").toArray();
			}
			$scope.dossierCustomFieldsBox = {
            	boxTitle: 'global.literals.customFields',
                permissions: {},
                content:  dossierCFs,
                config: { dossier: $scope.dossier },
                origin: 'tram-view'
            };
            $scope.dossierBox.boxDefinition.content = $scope.dossier.relatedDossiers;

            var relatedDossiers =  (($scope.dossier && $scope.dossier.relatedDossiers)?angular.copy($scope.dossier.relatedDossiers):[]);
            $scope.dossierBox.boxDefinition.content = [];
            _.forEach(relatedDossiers, function(relDos){
            	if(relDos.actual && relDos.actual.id == $scope.dossier.id){
            		relDos.inverse = false;
            		$scope.dossierBox.boxDefinition.content.push(relDos);
            	}else{
            		var daux = angular.copy(relDos.actual);
            		relDos.actual = relDos.related;
            		relDos.related =daux;
            		relDos.inverse = true;
            		$scope.dossierBox.boxDefinition.content.push(relDos);
            	}
            });
            if ($scope.dossier.thirds && $scope.dossier.thirds.length > 0) {
                angular.forEach($scope.dossier.thirds, function (value, key) {
                    if(value.principal){
						$scope.pricipalThird = angular.copy(value.third);
					}
					var dossierThird = new ThirdAddress(value.third, $scope.languageColumn, $scope.dossier);
                    $scope.receiverBox.boxDefinition.content.push({
                        id: value.id,
                        third: value.third,
                        interested: value.interested,
                        representative: value.representative,
                        principal: value.principal,
                        addresses: dossierThird.getAdressesHtml(),
                        representated: value.representated,
                        roleInterested: value.roleInterested
                    });
                });
            }

            $rootScope.$on('AnnexaServerEventUpdateThird', function(event, args) {
                if(args.third) {
                    var dossierThird = new ThirdAddress(args.third, $scope.languageColumn, $scope.dossier);

                    _.forEach($scope.receiverBox.boxDefinition.content, function (value, index) {
                        if(value.third.id == args.third.id) {
                            $scope.receiverBox.boxDefinition.content[index].third = args.third;
                            $scope.receiverBox.boxDefinition.content[index].addresses = dossierThird.getAdressesHtml();
                        }
                    });
                }
            });

            var boxDocuments = $linq($scope.dossier.dossierTransactions).select("x => x.documents").toArray();
            boxDocuments = [].concat.apply([], boxDocuments)
            boxDocuments = $linq(boxDocuments).select("x => x.document").toArray();
            $scope.boxes = [ $scope.receiverBox, $scope.registerInputBox, $scope.registerOutputBox, $scope.notificationBox, { boxDefinition: { title: 'global.literals.documents', content: boxDocuments } }, $scope.operationsAndInvoicesBox, $scope.rightAccessBox];

            $scope.showview = false;
            $scope.daysexpire = 0;
            $scope.expiredate = '';

            $scope.tabs = {
                principal: {
                    left: './views/layout/viewdossier/general_left.html',
                    center: './views/layout/viewdossier/general_center.html',
                    right: './views/layout/viewdossier/general_right.html'
                }
            }
            
            $scope.addAccessRight = function(){
            	TramFactory.addAccessRight();
            }
            
            HeaderService.onChangeState($scope, function (message) {
            	if(TramFactory.canUpdateDossier){
					  if (message.state.name == 'annexa.tram.pending.viewdossier' || message.state.name == 'annexa.tram.responsable.viewdossier') {
	                    if(!$rootScope.subHeadButtons){
	                    	$rootScope.subHeadButtons = [];
	                    }
	                	if($rootScope.isTransactionFinal==true) {
	                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#view-dossier', undefined, 'global.literals.finish', undefined, 'endDossier'));
	                    }
	                	var permissionsCancel = ['cancel_dossier'];
	                    if(TramFactory.isResponsible($scope.dossier) || ($scope.dossier.creatorUser && $rootScope.LoggedUser && $scope.dossier.creatorUser.id == $rootScope.LoggedUser.id)){
	                    	permissionsCancel.push('cancel_responsability_dossiers');
	                    }
	                    $rootScope.subHeadButtons.push(new HeadButton('btn text-white breadDivButton m-x-xs grey-500', undefined, '#view-dossier', 'fa fa-minus-circle', 'global.literals.anular', undefined, 'cancelDossierModal').setPermissions(permissionsCancel));

	                    if((TramFactory.isResponsible($scope.dossier) ||  $rootScope.esetMode || AnnexaPermissionsFactory.havePermission('create_dossier_transaction')) && $scope.dossier.procedure.guided == false){
	                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs ', undefined, '#view-dossier', undefined, 'global.literals.createTram', undefined, 'createTramResposibleModal'));
	                    }
	                    if($rootScope.app.configuration.show_dossier_foliate.value) { 
	                    	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs',undefined,'#view-dossier',undefined,'global.literals.foliate',undefined,'generateFoliate'));
	                    }
	                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#view-dossier', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditDossier').setPermissions('admin_audit'));
	                    if($rootScope.app.configuration.default_eni_values.show_dossier_eni){
	                    	var permission = '';
	                    	if($scope.dossier.responsibleUser.id == $scope.LoggedUser.id) {
	                    		permission = 'process_dossier';
	                    	} else {
	                    		permission = 'generate_document_interoperable';
	                    	}
	                		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#view-dossier', undefined, 'global.sec.literals.expEni', undefined, 'generateENIDossier').setPermissions(permission));
	                	}
	                }
				}
            });

            
            $scope.generateENIDossier = function(){
            	var eniDocumentType = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault(undefined, "x => x.id == "+$rootScope.app.configuration.default_eni_values.eni_dossier_document_type);
            	if(eniDocumentType){
            		DialogsFactory.confirm('global.documents.new.audit.actionType.ENI', 'global.literals.generate_dossier_ENI')
	                .then(function (data) {
	            		 var saveEniDossier = function(){
	            			 if (this.annexaFormly.form.$valid) {
	            	                var self = this;
	            	                var model = this.annexaFormly.model.modal_body;
	            	                var moduleDoc = 'EXPED';
	            	                var document = {
	            	                        createdDate: new Date(),
	            	                        modifyDate: new Date(),
	            	                        dateLastAccess: new Date(),
	            	                        urgent: false,
	            	                        favorite: false,
	            	                        downloaded: false,
	            	                        sentMail: false,
	            	                        type: {id: model.typeSelect},
	            	                        section: {id: model.section},
	            	                        profiles: [],
	            	                        module: moduleDoc,
	            	                        dateDocumentCreation: model.dateDocumentCreation
	        	                    };
	            	                var documentIndex = model.indexDocumentEni;
	            	                var dtDocumentIndex = model.dossierTransactionEni;
	            	                
	        	                    _.forEach(model.profiles, function(item) {
	        	                        document.profiles.push({ profile: { id: item } });
	        	                    });
	        	                    if(document.profiles.length == 0 && model.profile){
	        	                        document.profiles.push({ profile: { id: model.profile } });
	        	                    }
	        	                    if (model.archiveClassification && model.archiveClassification.$selected && model.archiveClassification.$selected.id) {
	        	                        document.archiveClassification = { id: model.archiveClassification.$selected.id };
	        	                    }
	        	                    document.sentMail = false;

	        	                    var cf = CustomFieldFactory.getModelValues(model);
	        	                    document.customFields = [];
	        	                    var documentType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + document.type.id);
	        	                    if(documentType && cf && cf.length > 0) {
	        	                        _.forEach(documentType.customFields, function(docTypeField) {
	        	                            var docCustomField = {
	        	                                customField: { id: docTypeField.customField.id },
	        	                                required: docTypeField.required,
	        	                                viewOrder: docTypeField.viewOrder,
	        	                                value: docTypeField.value,
	        	                                noEditable: docTypeField.noEditable
	        	                            };

	        	                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");

	        	                            if(cfValue) {
	        	                                if(docTypeField.customField.frontendType == 'CHECKBOX'){
	        	                                    var custom_field_selected = $linq(cf).where(function(x){
	        	                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
	        	                                    ).toArray();
	        	                                    if(custom_field_selected && custom_field_selected.length > 0){
	        	                                        docCustomField.value = '[';
	        	                                        _.forEach(custom_field_selected, function (item, key) {
	        	                                            if(item.value) {
	        	                                                if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
	        	                                                    var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
	        	                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
	        	                                                    ).toArray();
	        	                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
	        	                                                        docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
	        	                                                    }
	        	                                                }
	        	                                            }
	        	                                        });
	        	                                        docCustomField.value += ']';
	        	                                    }
	        	                                }else if(Array.isArray(cfValue.value)) {
	        	                                    if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
	        	                                        docCustomField.value = '[';

	        	                                        _.forEach(cfValue.value, function (item) {
	        	                                            docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
	        	                                        });

	        	                                        docCustomField.value += ']';
	        	                                    } else {
	        	                                        docCustomField.value = cfValue.value[0];
	        	                                    }
	        	                                } else {
	        	                                    if(cfValue.value instanceof Date) {
	        	                                        docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
	        	                                    } else {
	        	                                        docCustomField.value = cfValue.value;
	        	                                    }
	        	                                }
	        	                            }

	        	                            document.customFields.push(docCustomField);
	        	                        });
	        	                    }
	        	                    $http({
    			                        url: './api/tram/expedienteEni/' +$scope.dossier.id+'/'+documentIndex+'/'+dtDocumentIndex,
    			                        method: 'POST',
    			                        data: JSOG.encode(document)
    			                    }).then(function(data) {
    			                    	$rootScope.loading(false);
    			                    	if(data && data.data){
	    			                    	DialogsFactory.notify($filter('translate')('global.tram.literals.eniOK'));
	    			                    	self.close();
	    			                    	$state.reload();
    			                    	}else{
    			                    		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
	    			                    	self.close();
    			                    	}
    			                    }).catch(function(error) {
										if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
											var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
											DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
										}else{
					                		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
    	                                	console.log({msg: 'global.errors.unknownNew'});
										}
    			                    });
	            	            }
	            	                
	            		 } 
	                	var modal = DccumentsFactory.getOpenNewDocumentModal(undefined, undefined, 'EXPED', undefined, undefined, ((TramFactory.Dossier && TramFactory.Dossier.archiveClassification)?TramFactory.Dossier.archiveClassification:undefined), eniDocumentType, $scope.actualTransaction, undefined, undefined, [eniDocumentType]);
		           		modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.disabled = true;
		           		modal.annexaFormly.fields[0].fieldGroup[1].hideExpression = function(){return true;}
		           		modal.annexaFormly.fields[0].fieldGroup[2].hideExpression = function(){return true;}
		           		modal.annexaFormly.fields[0].fieldGroup.push({
                            key: 'indexDocumentEni',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                label: 'global.literals.indexDocumentEni',
                                valueProp: 'id',
                                labelProp: 'name',
                                placeholder: '',
                                options: [],
                                required: true,
                                clearHide: true
                            },
                            controller: ['$scope', '$filter', 'Language', 'GlobalDataFactory', 'TramFactory', 'AnnexaDocumentActionsButtonFactory', function($scope, $filter, Language, GlobalDataFactory, TramFactory, AnnexaDocumentActionsButtonFactory) {
                                $scope.to.options = [];
                                $scope.to.options.push({id: -1, name:$filter('translate')('global.literals.foliateTemplate')});
                                if(TramFactory && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions){
                                	_.forEach(TramFactory.Dossier.dossierTransactions, function(dt){
                                		if(dt.documents){
                                			_.forEach(dt.documents, function(doc){
                                				if(doc.relationType == 'DOCUMENT' && !doc.physical){
                                					$scope.to.options.push({id: doc.id, name:((doc.document)?doc.document.name:'')});
                                				}
                                			});
                                		}
                                	});
                                }
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            }
                        });
		           		modal.annexaFormly.fields[0].fieldGroup.push({
                            key: 'dossierTransactionEni',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                label: 'global.literals.dossierTransactionEni',
                                valueProp: 'id',
                                labelProp: 'name',
                                placeholder: '',
                                options: [],
                                required: true,
                                clearHide: true
                            },
                            controller: ['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                $scope.to.options = [];
                                if(TramFactory && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions){
                                	_.forEach(TramFactory.Dossier.dossierTransactions, function(dt){
                                		if(dt.actual){
                              				$scope.to.options.push({id: dt.id, name:dt[Language.getActiveColumn()]});
                                		}
                                	});
                                }
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            }
                        });
		           		AnnexaFormlyFactory.showModal('modalEniDocumentNew', modal, saveEniDossier, false, undefined);
		            }).catch(function(error) {
		            });
            	}else{
            		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
            	}
            }
            
            $scope.auditDossier = function () {
                if ($scope.dossier && $scope.dossier.id) {
                    window.open($state.href('annexa.admin.audit_dossier', {"objectId": $scope.dossier.id }), '_blank');
                }
            };

            $scope.bpmDossier = { graphJSON: $scope.dossier.graphJSON };

            $scope.getProfileNames = function(transaction){
                var profiles = '';
                if(transaction && transaction.processProfiles){
                    _.forEach(transaction.processProfiles, function(processProfile){
                        if(processProfile && processProfile.profile){
                            if(profiles == ''){
                                profiles += processProfile.profile[$scope.languageColumn];
                            }else{
                                profiles += ', '+processProfile.profile[$scope.languageColumn];
                            }
                        }
                    });
                }
                return profiles;
            }

            $scope.viewBPM = function() {
                ABMComponentFactory.getGraphImg()
                    .then(function (data) {
                        $scope.graphImg = data;
                    }).catch(function (error) {
                });
            };


            $scope.endDossier = function () {
                var dlg = dialogs.confirm(
                    $filter('translate')('global.literals.confirmEndDossierTitle'),
                    $filter('translate')('global.literals.confirmEndDossierBody'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: '',
                        backdropClass: 'zMax',
                        windowClass: 'zMax2'
                    }
                );

                dlg.result.then(function (btn) {
                    TramFactory.endDossier($scope.actualTransaction).then(function(data) {
                        $state.transitionTo('annexa.tram.pending');
                    }).catch(function (error) {
						if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							 $scope.dossierAlerts.push({msg: $filter('translate')('global.tram.errors.processTemplate'), msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
						}else if (error && error.data && error.data.message == 'No Required Custom Field Dossier Value data received in creation'){
							 $scope.dossierAlerts.push({msg: $filter('translate')('global.tram.errors.dossierCustomFields')});
						}else if (error && error.data && error.data.message == 'No Required Custom Field Value data received in creation'){
							 $scope.dossierAlerts.push({msg: $filter('translate')('global.tram.errors.dossierTransactionCustomFields')});
						}else if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
							 $scope.dossierAlerts.push({msg: $filter('translate')('global.documents.definitiveNotificationNoValidError')});
						}else{
		                     $scope.dossierAlerts.push({ msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data )});
						}
                    });
                }, function (btn) {
                    var a = 0;
                });

            }

            var calculateTransaction = function () {
                var actualTransaction = $linq($scope.dossier.dossierTransactions).where("x => x.id == "+$scope.dossierTransactionId).toArray();
                if (actualTransaction.length != 0 && actualTransaction[0] && actualTransaction[0].actual) {
                    $scope.actualTransaction = actualTransaction[0];
                    $scope.idTransactionSelectedTab = $scope.actualTransaction.id;
                } else {
                    actualTransaction = $linq($scope.dossier)
                        .join($scope.dossier.dossierTransactions, "x => x.id", "x => x.dossierId", "(outer, inner) => inner")
                        .where("x => x.actual == true")
                        .toArray();
                    if(actualTransaction.length != 0){
                        $scope.actualTransaction = actualTransaction[0];
                        $scope.idTransactionSelectedTab = $scope.actualTransaction.id;
                    }else{
                        $scope.actualTransaction = {};
                        $scope.actualTransaction[$scope.languageColumn] = '';
                        $scope.idTransactionSelectedTab = undefined;
                    }
                }
                var pastTransactions = $linq($scope.dossier.dossierTransactions).orderByDescending("x => x.createdDate").thenByDescending("x => x.id").toArray();
                if (pastTransactions.length != 0) {
                    angular.forEach(pastTransactions, function (value, key) {
                        $scope.transactions.push(value);
                    });
                }
                if($scope.actualTransaction && $scope.actualTransaction.id){
	                var actualTransactionIndex = $linq($scope.transactions).indexOf("x => x.id == "+$scope.actualTransaction.id);
	                if (actualTransactionIndex != -1) {
	                	 $scope.activeTransaction = actualTransactionIndex;
	                }else{
	                	$scope.activeTransaction = 0;
	                }
                }else{
                	$scope.activeTransaction = 0;
                }
                
            };

            $scope.transactions = [];
            var updateDocumentInList = function (document) {
                if(document && document.id) {
                	_.forEach($scope.transactions, function(transaction){
                		if(transaction.documents){
	                        var index = $linq(transaction.documents).indexOf("x => x.document.id == " + document.id);
	                        if (index != -1) {
	                        	transaction.documents[index].document.urgent = document.urgent;
	                        	transaction.documents[index].document.docStatus = document.docStatus;
	                        	transaction.documents[index].document.idPDFDocumentManager = document.idPDFDocumentManager;
	                        	transaction.documents[index].document.filePDFTypeDoc = document.filePDFTypeDoc;
	                        	transaction.documents[index].document.sizePDFDoc = document.sizePDFDoc;
	                        }
                		}

	               	 });
                }
            }

            $scope.$on('SendToSignModalFinishedFromTable', function(event,args){
                if (args.data && args.data.length > 0) {
                    updateDocumentInList(args.data[0]);
                }
            });
            
            $scope.$on('documentActionButtonExecuted', function (event, args) {
            	if (args.button) {
                    switch (args.button) {
	                    case 'publishDocument':	
	                        updateDocumentInList(args.data);
	                        break;
                    }
                }
            });
            
            $scope.$on('documentActionButtonExecutedFromTable', function (event, args) {
                if (args.button) {
                    switch (args.button) {
                        case 'deleteDossierTransactionDocument':
                        	var transactionAux = $linq($scope.transactions).firstOrDefault(undefined, "x => x.id == "+args.data.transactionId);
                            if(transactionAux && transactionAux.documents){
	                        	var indexDeleted = $linq(transactionAux.documents).indexOf("x => x.document.id == " + args.data.documentId);
	                            if (indexDeleted != -1) {
	                            	transactionAux.documents.splice(indexDeleted, 1);
	                            }
                            }
                            break;
                        case 'updateRelationTypeDossierTransactionDocument':
                        	var transactionAux = $linq($scope.transactions).firstOrDefault(undefined, "x => x.id == "+args.data.dossierTransaction.id);
                            if(transactionAux && transactionAux.documents){
	                        	var indexDTD = $linq(transactionAux.documents).indexOf("x => x.id == " + args.data.id);
	                            if (indexDTD != -1) {
	                            	transactionAux.documents[indexDTD].relationType = args.data.relationType;
	                            	transactionAux.documents[indexDTD].paperBin = args.data.paperBin;
	                            }  
                            }
                        	break;
                        case 'returnToDraft':
                        case 'complete':
                            updateDocumentInList(args.data);
                            break;
                        case 'deleteMultipleDossierTransactionDocument':
                        	_.forEach(args.data.documents, function(document){
                        		var transactionAux = $linq($scope.transactions).firstOrDefault(undefined, "x => x.id == "+document.dossierTransaction.id);
                                if(transactionAux && transactionAux.documents){
    	                        	var indexDeleted = $linq(transactionAux.documents).indexOf("x => x.document.id == " + document.document.id);
    	                            if (indexDeleted != -1) {
    	                            	transactionAux.documents.splice(indexDeleted, 1);
    	                            }
                                }
                			});
                        	
                            break;
                    }
                }
            });

            calculateTransaction();

            if(!$scope.actualTransaction.documents){
                $scope.actualTransaction.documents = [];
            }

            $scope.daysexpire = $scope.dossier.remainingDays;
            $scope.expireType = "BUSINESS";
            if($scope.dossier.expireType != null){
            	$scope.expireType = $scope.dossier.expireType;
            } else if($scope.dossier.procedure && $scope.dossier.procedure.expireType != null) {
            	$scope.expireType = $scope.dossier.procedure.expireType;
            }
            $scope.expiredate = HelperService.getExpirationDate(new Date(), $scope.daysexpire, $rootScope.LoggedUser.entity.holidays, $scope.expireType);

            // TODO: guiat - no guiat: treure el showEndProcess
            $rootScope.isTransactionFinal = ($scope.actualTransaction.showEndProcess==true || ABMShapesFactory.isTransactionFinal($scope.actualTransaction)==true);
            if($scope.actualTransaction && $scope.actualTransaction.dossier && $scope.actualTransaction.dossier.procedure && $scope.actualTransaction.dossier.procedure.guided == false){
                $rootScope.isTransactionFinal = false;
                var dossierTransactionIsFinal = false;
                if($scope.actualTransaction && $scope.actualTransaction.transactionType && $scope.actualTransaction.transactionType.finallyType){
                    dossierTransactionIsFinal = true;
                }
                var openTransactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual == true").toArray();
                if(openTransactions && openTransactions.length == 1 && dossierTransactionIsFinal) {
                    if(TramFactory.isResponsible(TramFactory.Dossier)){
                        $rootScope.isTransactionFinal = true;
                    }
                }
            }
            HeaderService.changeState($state.current);

            $scope.showview = true;

            $scope.backbutton = ($scope.transactions.length < 2) ? true : false;

            if (!$scope.backbutton) {
                $scope.previous = $scope.transactions[1];
            }
            else {
                $scope.previous = $scope.actualTransaction;
            }
            $scope.responsibleUserAux =  { user:undefined};
            if($scope.dossier.responsibleUser){
                var user_name = '';
                user_name = $scope.dossier.responsibleUser.name + ' ' + $scope.dossier.responsibleUser.surename1 +  ($scope.dossier.responsibleUser.surename2 ? ' ' +$scope.dossier.responsibleUser.surename2 : '');
                $scope.responsibleUserAux = {user: {id: $scope.dossier.responsibleUser.id, user: $scope.dossier.responsibleUser, value: user_name}};
            }
            $scope.dossierResponsibleProfilesAux = [];
            if($scope.dossier && $scope.dossier.dossierResponsibleProfiles && $scope.dossier.dossierResponsibleProfiles.length > 0){
                angular.forEach($scope.dossier.dossierResponsibleProfiles, function(value){
                    $scope.dossierResponsibleProfilesAux.push(value.profile);
                });
            }


            //region Nou Box registres

            var addRegisterEntrySave = function(registerEntry) {
				if(!Array.isArray(registerEntry)){
					registerEntry = [registerEntry];
				}
				DialogsFactory.confirm('global.tram.literals.addDocumentsThirdsMessage', 'global.tram.literals.addDocumentsThirdsMessageConfirm')
	            .then(function (data) {
					$http({
	                    url: './api/reg/register_entry_diligence/thirds_documents_from_register_entry/' + $linq(registerEntry).select("x => x.id").toArray(),
	                    method: 'GET'
	                }).then(function (dataAux) {
						if(dataAux && dataAux.data){
							dataAux = JSOG.decode(dataAux.data);
							var receivers = [];
							if(dataAux.thirds && dataAux.thirds.length > 0){
								_.forEach(dataAux.thirds, function(item) {
		                            receivers.push({
		                                id: item.third.id,
		                                allName: '<span style="display:inline">' + ThirdFactory.getThirdCompleteName(item.third) + ' <span class="label label-xxs">' + ThirdFactory.getRelatedThirdType(item) + '</span></span>'
		                            });
		                        });
							}
							var documentToAttach = [];
							if(dataAux.documents && dataAux.documents.length > 0){
							 	documentToAttach = $linq(dataAux.documents).select("x => x.document").toArray();
							}
		                    var dossierTransactionsToAttach = [];
		                    if($scope.dossier && $scope.dossier.dossierTransactions){
								dossierTransactionsToAttach = $linq($scope.dossier.dossierTransactions).where("x => x.actual").toArray();
								dossierTransactionsToAttach = angular.copy(dossierTransactionsToAttach);
							}
		                    var attachDossierModal = {
		                        title: 'global.reg.literals.attachExp',
		                        size: '',
		                        icon: 'fa fa-paperclip',
		                        fieldsDefinition: [
		                            { type: 'field', id: 'receivers', fieldType: 'select_multiple_html', colClass: 'daughter-label-strong', labelProp: 'allName', data: receivers, label: 'global.literals.remitents' },
		                            { type: 'field', id: 'documents', fieldType: 'component', componentType: 'annexa-select-documents',
		                                data: {
		                                    title: 'global.literals.documents',
		                                    documents: documentToAttach,
											documentsIdSelected: (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.diligence_attach_to_dossier_documents_all_default && $rootScope.app.configuration.diligence_attach_to_dossier_documents_all_default.value && documentToAttach && documentToAttach.length > 0)? $linq(documentToAttach).select("x => x.id").toArray() : undefined)	
		                                }
		                            },
		                            { type: 'field', id: 'dossierTransaction', fieldType: 'select', colClass: 'daughter-label-strong', data: dossierTransactionsToAttach, label: 'global.tram.literals.transactions', required: true}
		                        ],
		                        alerts: [],
		                        submitModal: function () {
		                        }
		                    }
		
		                    var submitAttachDossier = function() {
		                        var self = this;
		                        this.form.$setSubmitted();
		                        var selectedDocumentsAux = angular.copy($linq(this.fieldsDefinition[1].data.documents).where("x => x.selected").toArray());
		                        var selectedDocuments = [];
		                        if(selectedDocumentsAux && selectedDocumentsAux.length > 0){
		                        	_.forEach(selectedDocumentsAux, function(doc){
										var docAux = $linq(dataAux.documents).firstOrDefault(undefined, "x => x.document.id == "+doc.id);
		                        		if(docAux){
											selectedDocuments.push(docAux.id);
										}
		                        	});
		                        }
								var selectedReceivers = [];
		                        if(this.model.receivers && this.model.receivers.length > 0){
		                        	_.forEach(this.model.receivers, function(third){
										var thirdAux = $linq(dataAux.thirds).firstOrDefault(undefined, "x => x.third.id == "+third);
		                        		if(thirdAux){
											selectedReceivers.push(thirdAux.id);
										}
		                        	});
		                        }
								var addedRegisters = [];
								if(registerEntry && registerEntry.length){
									_.forEach(registerEntry, function(reg){
										addedRegisters.push(reg.id);
		                        	});
								}
								if (this.form.$valid) {
									$http({
						                url: './api/tram/attach_register_to_dossier_transaction/' + this.model.dossierTransaction+'/'+ CommonService.getParameterList(addedRegisters)+'/'+ CommonService.getParameterList(selectedReceivers)+'/'+CommonService.getParameterList(selectedDocuments),
						                method: 'PUT'
						            }).then(function(data) {
										try{
											var addedDocuments = [];
											var addedReceivers = [];
											if(data && data.data){
												data = JSOG.decode(data.data);
												addedDocuments = data.documents;
												addedReceivers = data.thirds;
											}
											var dossierTransactionToAttach = $linq($scope.dossier.dossierTransactions).firstOrDefault(undefined,"x => x.id == "+self.model.dossierTransaction);
							                if(registerEntry && registerEntry.length > 0){
												_.forEach(registerEntry, function(reg){
													if(reg && reg.entryType && reg.entryType == 'INPUT'){
														var existReg = $linq($scope.registerEntryInputBox.content).firstOrDefault(undefined, "x => x.id == "+reg.id);
														if(!existReg){
															$scope.registerEntryInputBox.content.push(reg);
														}
													}else if(reg && reg.entryType && reg.entryType == 'OUTPUT'){
														var existReg = $linq($scope.registerEntryOutputBox.content).firstOrDefault(undefined, "x => x.id == "+reg.id);
														if(!existReg){
															$scope.registerEntryOutputBox.content.push(reg);
														}
													}								
												});
											}
											if(addedReceivers && addedReceivers.length > 0 && $scope.receiverBox && $scope.receiverBox.boxDefinition && $scope.receiverBox.boxDefinition.content){
												_.forEach(addedReceivers, function(rec){
													if(rec.third && rec.third.id){
														var existRec = $linq($scope.receiverBox.boxDefinition.content).firstOrDefault(undefined, "x => x.third && x.third.id == "+rec.third.id);
														if(!existRec){
															$scope.receiverBox.boxDefinition.content.push(rec);
															TramFactory.Dossier.thirds.push(rec);
														}
													}
												});
											}
											if(addedDocuments && addedDocuments.length > 0 && dossierTransactionToAttach && dossierTransactionToAttach.id){
												if(!dossierTransactionToAttach.documents){
													dossierTransactionToAttach.documents = [];
												}
												_.forEach(addedDocuments, function(doc){
													if(doc.document && doc.document.id){
														var existDoc = $linq(dossierTransactionToAttach.documents).firstOrDefault(undefined, "x => x.document && x.document.id == "+doc.document.id);
														if(!existDoc){
															dossierTransactionToAttach.documents.push(doc);
														}
													}
												});
											}
						                	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
						                			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'asc') {
						                		$scope.registerEntryInputBox.content = $linq($scope.registerEntryInputBox.content).orderBy("x => x.registerDate").toArray();
						                    	$scope.registerEntryOutputBox.content = $linq($scope.registerEntryOutputBox.content).orderBy("x => x.registerDate").toArray();
						                	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
						    	        			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'desc') {
						                		$scope.registerEntryInputBox.content = $linq($scope.registerEntryInputBox.content).orderByDescending("x => x.registerDate").toArray();
						                    	$scope.registerEntryOutputBox.content = $linq($scope.registerEntryOutputBox.content).orderByDescending("x => x.registerDate").toArray();
						                	}
											attachDossierModal.close();
										}catch(e){
											$state.reload();
										}
						            }).catch(function(error) {
						                attachDossierModal.alerts.push({ msg: 'global.errors.unknownAttachtoExp' });
						            });
		                        }
			                }
			                AnnexaFormlyFactory.showAnnexaFormModal('modalAttachDossier', attachDossierModal, submitAttachDossier);
						}
					}).catch(function (error) {
						console.log(error);
					});
	            }).catch(function (data) {
	                $http({
	                    url: './api/tram/dossier/' + $scope.dossier.id + '/add_register_entry/' + $linq(registerEntry).select("x => x.id").toArray(),
	                    method: 'POST'
	                }).then(function (data) {
						if(registerEntry && registerEntry.length > 0){
							_.forEach(registerEntry, function(reg){
								if(reg && reg.entryType && reg.entryType == 'INPUT'){
									var existReg = $linq($scope.registerEntryInputBox.content).firstOrDefault(undefined, "x => x.id == "+reg.id);
									if(!existReg){
										$scope.registerEntryInputBox.content.push(reg);
									}
								}else if(reg && reg.entryType && reg.entryType == 'OUTPUT'){
									var existReg = $linq($scope.registerEntryOutputBox.content).firstOrDefault(undefined, "x => x.id == "+reg.id);
									if(!existReg){
										$scope.registerEntryOutputBox.content.push(reg);
									}
								}								
							});
						}
	                	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
	                			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'asc') {
	                		$scope.registerEntryInputBox.content = $linq($scope.registerEntryInputBox.content).orderBy("x => x.registerDate").toArray();
	                    	$scope.registerEntryOutputBox.content = $linq($scope.registerEntryOutputBox.content).orderBy("x => x.registerDate").toArray();
	                	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
	    	        			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'desc') {
	                		$scope.registerEntryInputBox.content = $linq($scope.registerEntryInputBox.content).orderByDescending("x => x.registerDate").toArray();
	                    	$scope.registerEntryOutputBox.content = $linq($scope.registerEntryOutputBox.content).orderByDescending("x => x.registerDate").toArray();
	                	}
	                }).catch(function(error) {
	                    console.log(error);
	                });
		        });
            };
			var completeAddRegisterEntryInputSave = function() {
        		completeAddRegisterEntrySave(this, $scope.registerEntryInputBox.content);
            };
			var completeAddRegisterEntryOutputSave = function() {
                completeAddRegisterEntrySave(this, $scope.registerEntryOutputBox.content);
                
            };
			var completeAddRegisterEntrySave = function(selfReg, content) {
				var newContent = [];
            	if(selfReg.searchedValue && selfReg.searchedValue.object && Array.isArray(selfReg.searchedValue.object)){
            		_.forEach(selfReg.searchedValue.object, function(obj){
            			newContent.push(JSOG.decode(obj));
            		});
            	}else{
            		newContent.push(JSOG.decode(selfReg.searchedValue.object));
            	}

        		selfReg.searchedValue = { id: -1, value: '' };
                selfReg.saveSearch(newContent);
            };
            var regInput = [];
            var regOutput = [];
            if($scope.dossier.relatedEntries){
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
        			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'asc') {
            		regInput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'INPUT'").orderBy("x => x.registerDate").toArray();
	            	regOutput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'OUTPUT'").orderBy("x => x.registerDate").toArray();
	        	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
	        			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'desc') {
            		regInput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'INPUT'").orderByDescending("x => x.registerDate").toArray();
	            	regOutput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'OUTPUT'").orderByDescending("x => x.registerDate").toArray();
	        	} else {
            		regInput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'INPUT'").toArray();
	            	regOutput = $linq($scope.dossier.relatedEntries).select("x => x.registerEntry").where("x => x.entryType == 'OUTPUT'").toArray();
	        	}
	        }

			var registerEntryInputBoxAux = {
                boxTitle: 'global.literals.reg_input',
                permissions: {},
                content:  regInput,
                config: {},
                origin: 'tram'
            }
			if($scope.isUpdateDossier){
                registerEntryInputBoxAux.permissions = { new: 'new_input_entry_register', view: ['view_input_register','view_all_input_register'] };
                registerEntryInputBoxAux.search = { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch: $scope.isUpdateDossier ? addRegisterEntrySave : undefined, completeAdd: $scope.isUpdateDossier ? completeAddRegisterEntryInputSave : undefined  };
                registerEntryInputBoxAux.new = {};
                registerEntryInputBoxAux.config = { dossier: $scope.dossier };
                registerEntryInputBoxAux.searchByEntryType = true;
                registerEntryInputBoxAux. entryType = 'INPUT';
			}
			$scope.registerEntryInputBox = registerEntryInputBoxAux;
			
			var registerEntryOutputBoxAux = {
                boxTitle: 'global.literals.reg_output',
                permissions: {},
                content:  regOutput,
                config: {},
                origin: 'tram'
            }
            
			if($scope.isUpdateDossier){
                registerEntryOutputBoxAux.permissions = { new: 'new_output_entry_register', view: ['view_output_register', 'view_all_output_register'] };
                registerEntryOutputBoxAux.search = { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch: $scope.isUpdateDossier ? addRegisterEntrySave : undefined, completeAdd: $scope.isUpdateDossier ? completeAddRegisterEntryOutputSave : undefined };
                registerEntryOutputBoxAux.new = {};
                registerEntryOutputBoxAux.config = { dossier: $scope.dossier };
                registerEntryOutputBoxAux.searchByEntryType = true;
                registerEntryOutputBoxAux.entryType = 'OUTPUT';
	        }

			$scope.registerEntryOutputBox = registerEntryOutputBoxAux;
            
			$scope.operationsAndInvoicesBox = {
            	boxTitle: 'global.operationsAndInvoices.title',
                permissions: (($scope.userAccountInstances && $scope.userAccountInstances.length > 0 && $scope.isUpdateDossier)?{new:'process_dossier'}:{}),
                content:  $scope.operationsAndInvoices ?  $scope.operationsAndInvoices : [],
                config: { dossier: $scope.dossier },
                origin: 'tram'
            };
            
			$scope.rightAccessBox = {
            	boxTitle: 'global.archive.accessRights', 
                permissions: {new:['admin_access_rights']},
                search: {},
                content:  $scope.rightAccesses,
                new: {newFunc: $scope.addAccessRight},
                config: { dossier: $scope.dossier },
                origin: 'tram',
				canShow: ((AnnexaPermissionsFactory.havePermission(['admin_access_rights']))?true:false)
            };
            
			var sessions = [];
            if (TramFactory.proposalsDossierTransaction && TramFactory.proposalsDossierTransaction.length > 0) {
            	 angular.forEach(TramFactory.proposalsDossierTransaction, function (pdt) {
            		 if(pdt.proposal && pdt.proposal.organs && pdt.proposal.organs.length > 0) {
                		 angular.forEach(pdt.proposal.organs, function (organ) {
                			 if(organ && organ.session && organ.session.id && !$linq(sessions).contains(organ.session, "(x, y) => x.id == y.id")) {
                        		 sessions.push(organ.session);
                			 }
                    	 });
            		 }
            	 });
              }
            
            $scope.sessionBox = {
                    content: sessions,
                    config: {},
                    origin: 'annexa-document',
                    isEdit: false
                }
			
            var decrees = [];
            if (TramFactory.proposalsDecree && TramFactory.proposalsDecree.length > 0) {
            	var decrees = TramFactory.proposalsDecree;
            }
            
            $scope.decreeBox = {
                    content: decrees,
                    config: {},
                    origin: 'annexa-document',
                    isEdit: false
                }
            
            $scope.dossierAuditBox = {
                content: angular.copy(TramFactory.archiveAuditInfo),
                show: _.contains(['CLOSED', 'TRANSFER', 'REJECTED', 'ARCHIVED'],$scope.dossier.dossierStatus)
            };
            
            $scope.$on('annexaBoxRegisterEntriesDelete', function (event, args) {
                if(args.origin && args.origin == 'tram') {
                   TramFactory.deleteRegister(TramFactory.Dossier.id, args.removedId, args.entryType).catch(function (error) {
                        console.log(error);
                    });
                }
            });
            
            $scope.$on('createdRegisterEntry', function (event, args) {
                if(args.id == 'modalNewRegisterEntry' && args.addNotifications && args.data && args.data.notifications &&  $scope.notificationBox &&  $scope.notificationBox.content) {
                	 _.forEach(args.data.notifications, function(notification){
                		 $scope.notificationBox.content.push(notification);
                		 if($scope.notificationBox.content){
                         	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_notification_dossier && 
                         			$rootScope.app.configuration.order_box_notification_dossier.value && $rootScope.app.configuration.order_box_notification_dossier.value == 'asc') {
                         		$scope.notificationBox.content = $linq($scope.notificationBox.content).orderBy("x => x.createdDate").toArray();
                         	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_notification_dossier && 
                         			$rootScope.app.configuration.order_box_notification_dossier.value && $rootScope.app.configuration.order_box_notification_dossier.value == 'desc') {
                         		$scope.notificationBox.content = $linq($scope.notificationBox.content).orderByDescending("x => x.createdDate").toArray();
                         	}
                         }
                	 });
                }
            });
            
            $scope.$on('createdRegisterEntry_from_document', function (event, args) {
                if(args.id == 'modalNewRegisterEntry') {
                	$scope.registerEntryOutputBox.content.push(args.data)

                    if($scope.registerEntryOutputBox.search.saveSearch) {
                    	$scope.registerEntryOutputBox.search.saveSearch(args.data);
                    }
                }
            })
            
            $scope.$on('allowGenerateFromContentDocumentTypeExecutedTram', function(event, args){
            	if(args && args.transactionId && $scope.dossier && $scope.dossier.dossierTransactions && args.data && args.data.id){
            		var transactionToAllow = $linq($scope.dossier.dossierTransactions).firstOrDefault(undefined, "x => x.id == "+args.transactionId);
            		if(transactionToAllow && transactionToAllow.id){
            			var saveDocumentToDossierTransaction = function(transaction, document){
                			var doc = {
	                             dossierTransaction: {id:transaction.id},
	                             document: document,
	                             publicDocument: false
	                        }
	                		if(transaction.documents == null || transaction.documents == undefined){
	                			transaction.documents = [];
	                		}
	                		transaction.documents.push(doc);
	                        TramFactory.updateDossierTransaction(transaction).then(function (data) {
	                            var docsTr = JSOG.decode(data).documents;
	                            if (docsTr) {
	                                transaction.documents.length = 0;
	                                angular.forEach(docsTr, function (value, key) {
	                                    transaction.documents.push(value);
	                                });
	                                $rootScope.$broadcast('proposalDocumentsList', {docs: TramFactory.getAllDossierDocuments()});
	                                $rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
	                                $rootScope.$broadcast('allowGenerateFromContentDocumentTypeAddedToTransaction', {});
	                                $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: transaction.documents, transactionId: ((transaction)?transaction.id:undefined) });
	                            }
	                        }).catch(function (error) {
	                        	transaction.documents.pop();
	                        	if(error && error.data && error.data.message == 'Error call to SUITE'){
	                        		DialogsFactory.error($filter('translate')('global.errors.errorConnectSuite'));
	                        	}else{
	                        		DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
	                        	}
	                        });
                    	}
                    	saveDocumentToDossierTransaction(transactionToAllow, args.data);
            		}
            	}
            });
            //endregion
        }else{
            $state.transitionTo('annexa.tram.pending');
        }
        $scope.loadUsers = CommonService.loadUsers;

        $scope.cancelDossierModal = function () {
            var modal = globalModals.cancelDossier;
            modal.annexaFormly.model = {};
            modal.annexaFormly.options = {};
            AnnexaFormlyFactory.showModal('modalSection', modal, $scope.cancelDossier, false);

        }

        $scope.cancelDossier = function (reason) {
            var modal = this;

            if(reason.annexaFormly.model.modal_body && reason.annexaFormly.model.modal_body.reason) {
                TramFactory.cancelDossier(reason.annexaFormly.model.modal_body.reason)
                    .then(function (data) {
                        modal.close();
                        $state.transitionTo('annexa.tram.pending');
                    }).catch(function (error) {
                });
            }
        };

        $scope.updateDossier = function (data, property) {
        	var dossierSubjectAux = $scope.dossier.subject;
            var dossierAux = angular.copy($scope.dossier);
            if(property == 'archiveClassification') {
                if (data && data.$selected && data.$selected.id) {
                    dossierAux[property] = {id: data.$selected.id};
                } else {
                    dossierAux[property] = {};
                }
            }else if(property == 'dossierResponsibleProfiles'){
            	dossierAux.archiveClassification = ((TramFactory.DossierArchiveClassification)?TramFactory.DossierArchiveClassification : {});
                if(data && data.length > 0){
                    dossierAux[property] = [];
                    angular.forEach(data, function(value){
                        dossierAux[property].push({profile:{id:value.id}});
                    });
                }
            }else if(property == 'responsibleUser'){
            	dossierAux.archiveClassification = ((TramFactory.DossierArchiveClassification)?TramFactory.DossierArchiveClassification : {});
                if(data.hasOwnProperty('user')) {
					if(data.user && data.user.id){
                    	dossierAux[property] = {id: data.user.id};
					}else{
						return $filter('translate')('global.validation.required');
					}
                } else if (data) {
                	dossierAux[property] = {id: data};
                }
            }else{
            	dossierAux.archiveClassification = ((TramFactory.DossierArchiveClassification)?TramFactory.DossierArchiveClassification : {});
                dossierAux[property] = data;
            }
            TramFactory.updateDossier(dossierAux, true)
                .then(function(returnData) {
                    var returnDataDecoded = JSOG.decode(returnData);
                    TramFactory.DossierArchiveClassification = returnDataDecoded.archiveClassification;
                    $scope.dossier.thirds = returnDataDecoded.thirds;
                    if(property == 'dossierTramitationType' && (!returnDataDecoded.dossierTramitationType || !returnDataDecoded.dossierTramitationType.allowUrgentProcess)){
                        dossierAux['dossierUrgent'] = false;
                        $scope.dossier['dossierUrgent'] = false;
                    }else if(property == 'dossierResponsibleProfiles') {
                        $scope.dossierResponsibleProfilesAux = data;
                        $scope.dossier['dossierResponsibleProfiles'] = returnDataDecoded.dossierResponsibleProfiles;
                        HeaderService.changeState($state.current);
                        $state.reload();
                    }else if(property == 'responsibleUser'){
                        if(returnDataDecoded.responsibleUser && returnDataDecoded.responsibleUser.id) {
                            $scope.dossier[property] = returnDataDecoded.responsibleUser;
                        }
                        if ($rootScope.esetMode) {
                        	$state.reload(); //se recarga la pagina para que carge el campo usuario responsable del tramite
                        }
                        HeaderService.changeState($state.current);                        
                    }
                }).catch(function (error) {
                	if(property == 'subject'){
                		$scope.dossier.subject = dossierSubjectAux;
                	}
                	if(error && error.data && error.data.message == 'Error call to SUITE'){
                		DialogsFactory.error($filter('translate')('global.errors.errorConnectSuite'));
                	}else{
                		DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                	}
            });
        };

        $scope.printDossierUrgent = function(){
            if($scope.dossier && $scope.dossier.dossierUrgent){
                return $filter('translate')('global.literals.yes');
            }else{
                return $filter('translate')('global.literals.no');
            }
        }

        $scope.generateFoliate = function(){
            TramFactory.generateFoliate();
        };

        $scope.createTramResposibleModal = function(){
            TramFactory.createTramResposibleModal($scope.actualTransaction);
        };

        $scope.transactionActiveTab = 0;

        $scope.$on('transactionTabSelected', function(event, args) {
            $scope.transactionActiveTab = args.tab;
        });

        $scope.getProfileValue = function(){
            var prof = '';
            if($scope.dossierResponsibleProfilesAux && $scope.dossierResponsibleProfilesAux.length > 0){
                angular.forEach($scope.dossierResponsibleProfilesAux, function(value){
                    if(prof){
                        prof += ", "+value[$scope.languageColumn];
                    }else{
                        prof += value[$scope.languageColumn];
                    }
                })
            }
            return prof;
        };

        $scope.printQdC = function(value) {
            var qdc = '';

            var getName = function(item, label) {

                if(label) {
                    label = ' / ' + label;
                }

                if(item){
                	label = ((item[$scope.languageColumn])?item[$scope.languageColumn]:((item.id)?item.id:'undefined')) + label;
	                if(item.parent) {
	                	label = getName(item.parent, label);
	                }
                }
                return label;
            }
            if(value && value.model && value.model.$selected && value.model.$selected.id) {
                var selectedQdc = $linq(GlobalDataFactory.archiveClassifications).singleOrDefault(undefined, "x => x.id == " + value.model.$selected.id);

                if(selectedQdc) {
                    qdc = getName(selectedQdc, qdc);
                }
            }else if(TramFactory.DossierArchiveClassification && TramFactory.DossierArchiveClassification.id){
            	 var selectedQdc = $linq(GlobalDataFactory.archiveClassifications).singleOrDefault(undefined, "x => x.id == " + TramFactory.DossierArchiveClassification.id);

                 if(selectedQdc) {
                     qdc = getName(selectedQdc, qdc);
                 }
            }

            return qdc;
        }
    }])
    .controller('TramDossierRightAccessController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory) {
        var dossierStatuses = [
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') },
            { id: 'CLOSED', name: $filter('translate')('CLOSED') },
            { id: 'TRANSFER', name: $filter('translate')('TRANSFER') },
            { id: 'REJECTED', name: $filter('translate')('REJECTED') },
            { id: 'ARCHIVED', name: $filter('translate')('ARCHIVED') }
        ];

        var tramitationEstates = [
            { id: 'TRAMITATION', name: $filter('translate')('global.literals.tramParticipated') },
            { id: 'SEE', name: $filter('translate')('global.literals.tramPendingSee') }
        ];

        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, favorite: '', langColumn: $scope.languageColumn, query: true };

        $scope.tfilter = [
            { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dossierNumber')},
            { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','family')},
            { id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','procedure'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','procedure')},
            { id: 'procedureClassification', type: 'text', order: 0, label: 'global.literals.classification', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','procedureClassification'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','procedureClassification')},
            { id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','subject')},
            { id: 'thirds', type: 'text', order: 3, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','thirds')},
            { id: 'transaction', type: 'text', order: 5, label: 'global.literals.transaction', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','transaction'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','transaction')},
            { id: 'custom_fields_search', type: 'text', order: 6, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','custom_fields_search')},
            { id: 'dates', type: 'dateRange', order: 7, label: 'global.delegate.startDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dates'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dates') },
            { id: 'dossierResponsibleUser', type: 'text', order: 8, label: 'global.tram.literals.dossierResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dossierResponsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dossierResponsibleUser')},
            (
                ($rootScope.esetMode)?
                { id: 'responsibleUser', type: 'text', order: 9, label: 'global.literals.responsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','responsibleUser')}
                    :
                { id: 'creator', type: 'text', order: 9, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','creator')}
            ),
            { id: 'register_entry', type: 'text', order: 10, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','register_entry')},
            { id: 'dossierStatus', type: 'select', order: 11, label: 'global.literals.state', dataType: 'LOCAL', data: angular.copy(dossierStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dossierStatus',dossierStatuses,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dossierStatus') },
            { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','address') }
        ];
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }
        
        $scope.FavoritesColumnRenderFunction = function (data) {
            if(data) {
                var users = $linq(data)
                    .where("x => x.user.id == " + $rootScope.LoggedUser.id)
                    .toArray();
                if (users.length != 0) {
                    return 'favorit';
                } else {
                    return 'nofavorit';
                }
            }
            return '';
        };
        
        $scope.FavoritesColumnModificar = function(id) {
        	TramFactory.getDossier(id).then(function(data){
        		var idDossierTransaction = 1;
        		var actualDossierTransaction = $linq(TramFactory.Dossier.dossierTransactions)
        			.where("x => x.actual == true").toArray();
        		if(actualDossierTransaction != null && actualDossierTransaction.lenght != null && actualDossierTransaction.lenght != 0){
        			idDossierTransaction = actualDossierTransaction[0].id;
        		} else {
        			idDossierTransaction = TramFactory.Dossier.dossierTransactions[0].id;
        		}
        		TramFactory.modifyFavorite(idDossierTransaction, $rootScope.LoggedUser.id).then(function(data){
                    $scope.tableDefinition.reloadInternalData(false,true);
                }).catch(function(error){
                    $scope.tableDefinition.reloadInternalData(false, true);
                });
            }).catch(function(error){
                $scope.tableDefinition.reloadInternalData(false, true);
            });
        }
        
        $scope.FavoritesColumn = new IconSearchColumn(
                'favoriteUsers',
                '<iconsearchcolumn definition="FavoritesColumn" filterModel="filterData" fieldName="favorite"/>',
                '5%',
                new IconSearchRender($scope, 'FavoritesColumn'),
                false,
                true,
                [
                    new IconSearchOption('favorit','favorit', 'fa-star text-warn text-lg', 'fa-star text-warn text-lg', 'global.literals.favorite'),
                    new IconSearchOption('nofavorit','nofavorit', 'fa-star-o text-lg', 'fa-star-o text-lg', 'global.literals.noFavorite'),
                ],
                'tableDefinition',
                $scope.FavoritesColumnRenderFunction,
                'FavoritesColumnModificar'
            );
        
        var DaysColumn = new RightAccessRemaingDaysColumn($filter, 'global.literals.days');
        var StatusColumn = new DossierStatusColumn($filter, 'global.literals.dossier');
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn);
        var TranCounterColumn = new TransactionsCounterColumn();
        var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
        var DossierTransactionActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','openDossier([data], [bigDossier])',undefined,false,undefined, undefined, ['bigDossier']),undefined,'id');
        var PrincipalColumn = new PrincipalThirdColumn($filter,'global.literals.principalDest');
        var CreatedDateColumn = new DateColumn($filter, 'global.literals.creation_date');
       	var dossierResponsibleColumn = new DossierResponsibleColumn($filter, 'global.tram.literals.dossierResponsible');
        var SubjectColumn = new DossierSubjectColumn($filter, 'global.literals.subject', $scope.languageColumn);

        var DossierTransactionStatusRender = function(data, type, full, meta) {
            var content = '';

            if(data) {
                content = '<i class="text-success fa fa-circle" title="' + $filter('translate')('global.literals.closedMS') + '" aria-hidden="true"></i> ';
            } else {
                content = '<i class="text-warn fa fa-circle" title="' + $filter('translate')('global.literals.openedMS') + '" aria-hidden="true"></i> ';
            }

            return content;
        }

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            return filterCall;
        }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.filterOrigin = 'dossierRightAccess';
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.favorite = $scope.filterData.favorite;
            return filterCallAux;
        }

        var columnsAux = [
        	{ id: 'favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender(), sortable: false},
            { id: 'dossierStatus', width: '50px', column: StatusColumn },
            { id: 'remainingDays', width: '40px', column: DaysColumn },
            { id: 'dossierNumber', width: '140px', title: $filter('translate')('global.literals.code') },
            { id: 'procedure.' + ProcedureColumn.getColumn(), width: '140px', title:ProcedureColumn.getTitle()},
            { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
            { id: 'subject', width: '150px', title: SubjectColumn.getTitle(),  render: SubjectColumn.getRender },
            { id: 'principalThird', width: '200px', column: PrincipalColumn, sortable: false },
            { id: 'responsibleUser.name', width: '110px', column: dossierResponsibleColumn},
            { id: 'creatorUser', width: '150px', column: CreatorUserColumn},
            { id: 'createdDate', width: '100px', column:  CreatedDateColumn},
            { id: 'actions', columnName: 'id', width: '40px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_view', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_view', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossier.dossierNumber', sort: [[7, 'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
            id: 'tableDossierRightAccess',
            origin: 'tram',
            objectType: 'Dossier',
            callOrigin: 'dossierRightAccess',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.openDossier = function (id, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	$state.transitionTo('annexa.tram.dossierRightAccess.viewdossier', ({ dossier: id }));	            
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		$state.transitionTo('annexa.tram.dossierRightAccess.viewdossier', ({ dossier: id }));
        	}
        }

        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }
    }])
    .controller('TramNewDossierController',['$scope', '$rootScope', '$state', '$stateParams', 'TramFactory', 'Language', 'HeaderService', 'AnnexaEntityFactory', function($scope, $rootScope, $state, $stateParams, TramFactory, Language, HeaderService, AnnexaEntityFactory) {
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.tram.newdossier') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#new-dossier','fa-floppy-o','global.literals.save',undefined,'saveEntity'),
                    new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#new-dossier',undefined,'global.literals.cancel',undefined,'cancelEntity')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.translations = { procedure: TramFactory.Procedure[Language.getActiveColumn()] };
        
        $scope.daysexpire = TramFactory.Procedure.maxDays;
        $scope.expiretime = TramFactory.maxDays;
        $scope.expiredate = TramFactory.expireDate;
        $scope.expireType = TramFactory.Procedure.expireType;

        $scope.entity = AnnexaEntityFactory.getNewTramForm($scope, TramFactory.Diligence);

    }])
	.controller('TramByNumberController',['$scope', 'TramFactory', '$state', '$stateParams', function ($scope, TramFactory, $state, $stateParams) {
		if(TramFactory.byNumberId && TramFactory.byNumberDtId && TramFactory.byNumberCanUpdate === true){
    		$state.go('annexa.tram.pending.viewdossier', {"dossier" : TramFactory.byNumberId, "dossierTransaction":TramFactory.byNumberDtId});
        }else if(TramFactory.byNumberId && TramFactory.byNumberRightAccess === false && TramFactory.byNumberCanUpdate === false){
			$state.go('annexa.tram.view.viewdossier', {"dossier" : TramFactory.byNumberId});
        }else if(TramFactory.byNumberId && TramFactory.byNumberCanUpdate === true){
			$state.go('annexa.tram.view.viewdossier', {"dossier" : TramFactory.byNumberId});
        }else if(TramFactory.byNumberId && TramFactory.byNumberRightAccess === true){
			$state.go('annexa.tram.dossierRightAccess.viewdossier', {"dossier" : TramFactory.byNumberId});
        }else{
        	$state.go('annexa.dashboard');
        }
    }]);